import {
  Icons,
  Menu,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { TabLabelContainer } from '../../styled'
import { limitText } from '../../../../helpers/limitText'
import Space from '@components/Space'

type TabLabelProps = {
  options: Array<{
    children: JSX.Element
    key: string
  }>
  name: string
}

const TabLabel: React.FC<TabLabelProps> = ({ name, options }) => {
  const MAX_TEXT_LENGTH = 25
  const shouldShowTooltip = name.length > MAX_TEXT_LENGTH
  const truncatedText = limitText({ limit: MAX_TEXT_LENGTH, text: name })

  const content = shouldShowTooltip
    ? (
      <Tooltip arrow={true} placement="top" title={name}>
        <span>
          <Typography variant="body4">{truncatedText}</Typography>
        </span>
      </Tooltip>
    )
    : (
      <Typography variant="body4">{truncatedText}</Typography>
    )

  return (
    <TabLabelContainer>
      {content}
      <Menu
        type={'select'}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ horizontal: 30, vertical: 0 }}
      >
        <Space y="xs5" />
        <Icons name="IconDotsVertical" />
      </Menu>
    </TabLabelContainer>
  )
}

export default TabLabel
