import { BoxStyled } from '../styled'
import { Tag, TagProps, Typography } from '@flash-tecnologia/hros-web-ui-v2'

type TagsArrayType = {
  tags: string[]
  variant: TagProps['variant']
  numberTags?: number
  tagLength?: number
}

const MAX_TAG_LENGTH = 10

const TagsArray = ({
  numberTags = 3,
  tags = [],
  variant,
  tagLength = MAX_TAG_LENGTH,
}: TagsArrayType) => {
  const arraySize = tags.length
  const plusTags = arraySize - numberTags

  const newTags = tags.slice(0, numberTags).map(tag => {
    return tag.length > tagLength ? tag.slice(0, tagLength) + '...' : tag
  })
  if (plusTags > 0) {
    newTags.push(`+${plusTags}`)
  }

  return (
    <BoxStyled>
      {newTags.map(tag => (
        <Tag
          variant={variant}
          disabled={true}
          key={`${tag}_${Math.random() * 1000}`}
        >
          <Typography variant="caption">{tag}</Typography>
        </Tag>
      ))}
    </BoxStyled>
  )
}

export default TagsArray
