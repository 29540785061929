import { Button, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const StyledEmptyStateMessage = styled(Typography)`
  text-align: center;
  line-height: ${({ theme }) => theme.spacings.xs1};
`

export const StyledEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const StyledEmptyStateButton = styled(Button)`
  align-self: center !important;
`
