export const formatCPF = (unformattedCPF: string | number) => {
  return unformattedCPF
    .toString()
    .padStart(11, '0')
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const toCurrencyString = (value: number, noPrefix = false) => {
  const prefix = noPrefix ? '' : 'R$'
  if (isNaN(Number(value))) return 'R$ 0,00'
  const currency = `${prefix} ${value.toFixed(2).replace('.', ',')}`
  return currency.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
