import { ProgressBar } from '@components/ProgressBar'
import { TextInLineStyled } from './styled'
import ActionsButton from './components/ActionsButton'
import IconsAndLabel from './components/IconsAndLabel'
import TagsArray from './components/TagsArray'

const getColumnsBenefitChoices = ({ navigate }) => {
  const columns = [
    {
      Cell: ({ value }) => <TextInLineStyled>{value}</TextInLineStyled>,
      Header: <TextInLineStyled>Escolha</TextInLineStyled>,
      accessor: 'name',
      customSortKey: 'name',
    },
    {
      Cell: ({ value }) => (
        <TagsArray variant={'pink'} tags={value} numberTags={2}></TagsArray>
      ),
      Header: <TextInLineStyled>Benefícios da escolha</TextInLineStyled>,
      accessor: 'benefits',
      customSortKey: 'benefits',
      disableSortBy: true,
    },
    {
      Cell: ({ value }: { value?: string }) => {
        const defaultTagName = value ? [value] : ['Nenhum benefício']
        return (
          <TagsArray
            variant={value ? 'pink' : 'gray'}
            tags={defaultTagName}
            tagLength={30}
          />
        )
      },
      Header: <TextInLineStyled>Benefício padrão</TextInLineStyled>,
      accessor: 'default',
      customSortKey: 'default',
      disableSortBy: true,
    },
    {
      Cell: ({ value }) => (
        <IconsAndLabel icon={'IconUsers'} label={value || '0'} />
      ),
      Header: <TextInLineStyled>Número de pessoas</TextInLineStyled>,
      accessor: 'totalEmployees',
      customSortKey: 'totalEmployees',
      disableSortBy: true,
    },
    {
      Cell: ({ value }) => <ProgressBar value={Number(value ?? 0)} />,
      Header: <TextInLineStyled>Escolhas realizadas</TextInLineStyled>,
      accessor: 'totalChoicesMade',
      customSortKey: 'totalChoicesMade',
      disableSortBy: true,
    },
    {
      Cell: ({ value }) => <ProgressBar value={Number(value ?? 0)} />,
      Header: <TextInLineStyled>Escolhas aplicadas</TextInLineStyled>,
      accessor: 'totalAppliedChoices',
      customSortKey: 'totalAppliedChoices',
      disableSortBy: true,
    },
    {
      Cell: ({ value }) => {
        const date = new Date(value).toString()
        const dateFormatted = new Date(date)
          .toLocaleDateString('en-GB')
          .toString()
        return (
          <IconsAndLabel icon={'IconCalendarEvent'} label={dateFormatted} />
        )
      },
      Header: <TextInLineStyled>Data da criação</TextInLineStyled>,
      accessor: 'createdAt',
      customSortKey: 'createdAt',
    },
    {
      Cell: ({ value: choiceId }) => (
        <ActionsButton
          actionsOptions={[
            {
              icon: 'IconFileDescription',
              label: 'Ver detalhes da escolha',
              onClick: () => navigate(`/benefit-choices/choice/${choiceId}`),
            },
            {
              icon: 'IconPencil',
              label: 'Editar informações básicas',
              onClick: () =>
                navigate(`/benefit-choices/edit-choice/${choiceId}`),
            },
            {
              icon: 'IconUserPlus',
              label: 'Adicionar pessoas',
              onClick: () =>
                navigate('/benefit-choices/assign-employees-to-choice', {
                  state: { choiceId },
                }),
            },
          ]}
        />
      ),
      Header: <TextInLineStyled>Ações</TextInLineStyled>,
      accessor: 'id',
      customSortKey: 'id',
      disableSortBy: true,
      sticky: 'right',
    },
  ]

  return columns
}

export default getColumnsBenefitChoices
