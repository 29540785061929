import { HeaderContainerStyled, TitleStyled } from './styled'
import { Tag } from '@flash-tecnologia/hros-web-ui-v2'

export const Title = ({ title, totalCount, loading }) => {
  return (
    <HeaderContainerStyled>
      <TitleStyled variant="headline8">{title}</TitleStyled>
      <Tag disabled variant={'gray'} loading={loading}>
        {`${totalCount} itens`}
      </Tag>
    </HeaderContainerStyled>
  )
}
