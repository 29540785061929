export default {
  translations: {
    choices: {
      createChoices: 'Criar Escolhas',
      noChoices: 'Você ainda não criou escolhas',
      notFound: {
        buttonAriaLabel: 'Abrir fluxo de criação de escolhas',
        buttonLabel: 'Nova escolha',
        description:
          'Crie opções de escolha de benefícios para seus colaboradores',
        title: 'Você ainda não cadastrou escolhas',
      },
      searchChoice: 'Buscar por escolha',
    },
    createExternalBenefit: {
      addDescription: 'Adicione uma descrição',
      basicBenefitData: 'Dados básicos do benefício',
      benefitData: 'Dados do benefício',
      benefitIcon: 'Ícone de identificação do benefício',
      benefitIdentificationIcon: 'Ícone de identificação do benefício',
      benefitName: 'Nome do benefício',
      companyBenefits: 'Benefícios da empresa',
      description: 'Descrição',
      edit: 'Editar',
      editExternalBenefit: 'Editar benefício externo',
      emoji: 'Emoji',
      enterBenefitBasicInformation:
        'Defina as informações básicas do benefício a ser criado',
      errorEmptyBenefitEmoji:
        'Por favor, preencha o campo Ícone de identificação',
      errorEmptyBenefitName: 'Por favor, preencha o campo Nome do benefício',
      externalBenefitAlreadyExists:
        'Erro ao criar o benefício. Verifique as informações e tente novamente.',
      failedToCreateExternalBenefit:
        'Erro ao criar o benefício. Tente novamente mais tarde.',
      failedToEditExternalBenefit:
        'Erro ao editar o benefício. Tente novamente mais tarde.',
      failedToGetExternalBenefit:
        'Erro ao encontrar o benefício. Tente novamente mais tarde.',
      identificationIcon: 'Ícone de identificação',
      item_one: 'item',
      item_other: 'itens',
      name: 'Nome',
      nameInUse: 'Esse nome já está em uso por outro benefício',
      newExternalBenefit: 'Novo benefício externo',
      noExternalBenefitsMessage:
        'Você ainda não adicionou um benefício externo.\
        Utilize nossa plataforma para gerenciar benefícios de outros provedores.',
      notFoundExternalBenefit: 'Nenhum benefício externo encontrado.',
      paginationText:
        'Resultados: {{currentPageItemStart}} - {{currentPageItemEnd}} de {{totalItems}}',
      save: 'Salvar',
      sixHundredCharsMax: 'Máximo 600 caracteres',
      successfullyCreatedExternalBenefit:
        'Benefício externo criado com sucesso!',
      successfullyEditedExternalBenefit:
        'Benefício externo editado com sucesso!',
    },
    emptyExternalBenefitAssign: {
      benefitsNotFound: {
        buttonAriaLabel: 'Abrir fluxo de criação de benefício externo',
        buttonLabel: 'Novo benefício externo',
        description:
          'Utilize nossa plataforma para gerenciar benefícios de outros provedores.',
        title: 'Você ainda não adicionou um benefício externo',
      },
      emptyAssignMessage: 'Você ainda não atribuiu pessoas a este benefício',
      emptySearchResultMessage:
        'Nenhum colaborador atribuído a este benefício encontrado',
      filter: 'Filtrar por',
      group: 'Grupo',
      searchBenefit: 'Buscar benefício',
      searchEmployees: 'Buscar por pessoas',
    },
    listExternalBenefits: {
      benefit: 'Benefício',
      createExternalBenefits: 'Criar benefício externo',
      description: 'Descrição',
      details: 'Detalhes',
      edit: 'Editar',
      editExternalBenefit: 'Editar benefício',
      name: 'Nome',
      noBenefits: 'Nenhum benefício externo encontrado',
      notFound: {
        description:
          'Utilize nossa plataforma para gerenciar benefícios de outros provedores.',
        marketplaceCTA: 'Conhecer Flash Encanta',
        newExternalBenefit: 'Novo benefício externo',
        newExternalBenefitAriaLabel:
          'Abrir fluxo de criação de benefício externo',
        title: 'Você ainda não tem nenhum benefício externo.',
      },
      page: {
        helloworld: 'Olá mundo com I18n',
      },
      searchExternalBenefits: 'Buscar benefícios externos',
    },
  },
}
