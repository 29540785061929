import { Theme } from '@mui/material/styles/createTheme'
import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { WrapperTextsStyled } from './styled'

type TextsProps = {
  title: string
  description?: string
  theme: Theme
}

export function Text({ title, description, theme }: TextsProps) {
  const renderDescription = () => {
    if (description) {
      return (
        <Typography variantColor={theme.colors.neutral[20]} variant="body3">
          {description}
        </Typography>
      )
    }

    return null
  }

  return (
    <WrapperTextsStyled>
      <Typography variantColor={theme.colors.neutral[30]} variant="headline6">
        {title}
      </Typography>

      {renderDescription()}
    </WrapperTextsStyled>
  )
}
