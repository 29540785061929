import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient, trpc, trpcClient } from '@api/client'
import ExternalBenefitSimpleAssign from '../../pages/ExternalBenefitAssignments'

const ExternalBenefitSimpleAssignTab = ({ navigate = undefined }) => {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ExternalBenefitSimpleAssign navigate={navigate} />
      </QueryClientProvider>
    </trpc.Provider>
  )
}

export default ExternalBenefitSimpleAssignTab
