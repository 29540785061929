/* eslint-disable react-hooks/exhaustive-deps */
import { trpc } from '@api/client'
import { useDispatchToast } from '@utils/hooks/useDispatchToast'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'
import { useTheme } from '@mui/material'

type ChoiceType = {
  companyId: string
  description: string
  id: string
  name: string
}

export const useEditChoice = () => {
  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()
  const { choice_id: choiceId } = useParams()
  const { dispatchToast } = useDispatchToast()
  const navigate = useNavigate()
  const theme = useTheme()

  const [choice, setChoice] = useState<ChoiceType>({
    companyId,
    description: '',
    id: '',
    name: '',
  })

  const { isLoading: getChoiceloading } = trpc.getChoice.useQuery(
    { companyId, id: choiceId || '' },
    {
      enabled: !choice.id,
      onError: () =>
        dispatchToast({
          description: 'Tente novamente mais tarde.',
          title: 'Erro ao buscar escolha.',
          type: 'error',
        }),
      onSuccess: data => {
        setChoice({
          companyId,
          description: data?.description || '',
          id: choiceId || '',
          name: data?.name || '',
        })
      },
    }
  )

  const editChoiceMutation = trpc.editChoice.useMutation({
    onError: () => {
      dispatchToast({
        description: 'Tente novamente mais tarde.',
        title: 'Erro ao editar escolha.',
        type: 'error',
      })
    },
    onSuccess: () => {
      dispatchToast({
        title: 'Escolha editada com sucesso!',
        type: 'success',
      })
      navigate('/simple-assignment/benefit-choices')
    },
  })

  const handleConfirm = () => {
    if (!choice.name.trim().length) {
      dispatchToast({
        description: 'Nome não pode ser vazio',
        title: 'Erro ao editar escolha.',
        type: 'error',
      })
      return
    }

    editChoiceMutation.mutate({
      ...choice,
      description: choice.description.trim(),
      name: choice.name.trim(),
    })
  }

  const handleChangeInput = ({ target }) => {
    setChoice({
      ...choice,
      [target.id]: target.value,
    })
  }

  const handleCancel = () => {
    navigate('/simple-assignment/benefit-choices')
  }

  return {
    choice,
    editChoiceloading: editChoiceMutation.isLoading,
    getChoiceloading,
    handleCancel,
    handleChangeInput,
    handleConfirm,
    navigate,
    theme,
  }
}
