import { ContainerStyled } from './styled'
import { Typography } from '@flash-tecnologia/hros-web-ui-v2'

const InformationBlock = ({ label, content, theme }) => {
  return (
    <div>
      <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
        {label}
      </Typography>
      <Typography variant="body3" variantColor={theme.colors.neutral[40]}>
        {content}
      </Typography>
    </div>
  )
}

const ChoiceInformation = ({ benefitNames, description, name, theme }) => (
  <ContainerStyled>
    {[
      { content: name, label: 'Nome' },
      { content: description, label: 'Descrição' },
      {
        content: [benefitNames[0], ...(benefitNames.slice(1).map(name => [<br />, name]) ?? [])],
        label: 'Benefícios',
      },
    ].map(({ label, content }) => (
      <InformationBlock label={label} content={content} theme={theme} />
    ))}
  </ContainerStyled>
)

export { ChoiceInformation }
