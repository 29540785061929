import { ContainerStyled, SubinfoStyled } from './styled'
import {
  GenericProfilePicture,
  Icons,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { formatCPF } from 'bff/src/utils/formatters'
import { useTheme } from 'styled-components'

type ExternalBenefitItemType = {
  name: string
  cpf: string
  hasAttribution?: boolean
  isFromChoice?: boolean
  hasAttributionInOtherChoice?: boolean
}

const EmployeeActive = ({ name, theme, hasAttribution, isFromChoice, cpf }) => {
  const isFromChoiceTooltipText = `Essa pessoa já está atribuída a um dos benefícios selecionados.
    Se ela for incluída nesta escolha, o benefício atribuído poderá mudar.`
  const isFromBenefitTooltipText = `Essa pessoa já está atribuída a um dos benefícios selecionados.
    Ao prosseguir, a atribuição atual será mantida.`

  const attributionTextTooltipTitle = isFromChoice
    ? isFromChoiceTooltipText
    : isFromBenefitTooltipText

  return (
    <ContainerStyled>
      <GenericProfilePicture name={name} size={40} />
      <div>
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[40]}
          weight={600}
        >
          {name}
        </Typography>
        <SubinfoStyled>
          {hasAttribution
            ? (
              <Tooltip title={attributionTextTooltipTitle}>
                <span>
                  <SubinfoStyled>
                    <Icons
                      name="IconInfoCircle"
                      size={16}
                      color={theme.colors.feedback.error[40]}
                    />
                    <Typography
                      variant="body4"
                      variantColor={theme.colors.feedback.error[40]}
                      weight={400}
                    >
                    Benefício já atribuído
                    </Typography>
                  </SubinfoStyled>
                </span>
              </Tooltip>
            )
            : (
              <>
                <Icons name="IconId" size={16} />
                <Typography
                  variant="body4"
                  variantColor={theme.colors.neutral[60]}
                  weight={400}
                >
                  {formatCPF(cpf)}
                </Typography>
              </>
            )}
        </SubinfoStyled>
      </div>
    </ContainerStyled>
  )
}

const InactiveEmployee = ({ name, theme }) => {
  return (
    <ContainerStyled>
      <GenericProfilePicture name={name} size={40} />
      <div>
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[80]}
          weight={600}
        >
          {name}
        </Typography>
        <SubinfoStyled>
          <Tooltip
            title={`Essa pessoa já está atribuída a uma outra escolha envolvendo pelo menos
            um benefício selecionado. Para incluí-la aqui, você deve retirá-la da outra escolha.`}
          >
            <SubinfoStyled>
              <Icons
                name="IconAlertTriangle"
                size={16}
                color={theme.colors.neutral[40]}
              />
              <Typography
                variant="body4"
                variantColor={theme.colors.neutral[40]}
                weight={400}
              >
                Conflito de escolhas
              </Typography>
            </SubinfoStyled>
          </Tooltip>
        </SubinfoStyled>
      </div>
    </ContainerStyled>
  )
}

const EmployeeItem = ({
  name,
  cpf,
  hasAttribution,
  isFromChoice = false,
  hasAttributionInOtherChoice = false,
}: ExternalBenefitItemType) => {
  const theme = useTheme()

  return (
    <>
      {hasAttributionInOtherChoice
        ? (
          <InactiveEmployee name={name} theme={theme} />
        )
        : (
          <EmployeeActive
            name={name}
            theme={theme}
            hasAttribution={hasAttribution}
            isFromChoice={isFromChoice}
            cpf={cpf}
          />
        )}
    </>
  )
}

export default EmployeeItem
