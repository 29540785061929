import { BasicInformationRightPanel } from './BasicInformation'
import {
  Button,
  Focused,
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import {
  ContentGridContainerStyled,
  LeftPanelContainerStyled,
  RightPanelContainerStyled,
} from './styled'
import { useEditChoice } from './useEditChoice'
import LeftContent from '@components/LeftContent'
import Space from '@components/Space'

const EditChoice = () => {
  const {
    choice,
    editChoiceloading,
    getChoiceloading,
    handleCancel,
    handleChangeInput,
    navigate,
    handleConfirm,
    theme,
  } = useEditChoice()
  return (
    <Focused
      navigate={navigate}
      footer={{
        endActions: [
          <Button
            disabled={editChoiceloading || getChoiceloading}
            size="large"
            variant="primary"
            key="confirm-button"
            onClick={handleConfirm}
          >
            Salvar
            <Icons name="IconArrowRight" />
          </Button>,
        ],
        startActions: [
          <LinkButton variant="secondary" onClick={handleCancel}>
            Cancelar
          </LinkButton>,
        ],
      }}
      routes={[
        {
          label: 'Atribuição de benefícios',
          route: '/simple-assignment',
        },
        {
          label: 'Escolhas',
          route: '/simple-assignment/benefit-choices',
        },
        {
          label: 'Editar escolha',
        },
      ]}
    >
      <>
        <Space y="s" />
        <Typography variant="headline6" variantColor={theme.colors.neutral[10]}>
          Editar escolha
        </Typography>
        <Space y="s" />
        <ContentGridContainerStyled>
          <LeftPanelContainerStyled>
            <LeftContent
              title={'Dados básicos'}
              description={'Defina as informações básicas da escolha'}
            />
          </LeftPanelContainerStyled>
          <RightPanelContainerStyled>
            <BasicInformationRightPanel
              choice={choice}
              handleChangeInput={handleChangeInput}
              loading={getChoiceloading}
              theme={theme}
            />
          </RightPanelContainerStyled>
        </ContentGridContainerStyled>
      </>
    </Focused>
  )
}

export default EditChoice
