import { EVENTS } from '@utils/consts/tracking'
import { set } from 'lodash-es'
import { trpc } from '@api/client'
import { useEventTracking } from '@utils/hooks/useEventTracking'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'

type GroupType = {
  id: string
}
type EmployeeType = {
  _id: string
  cpf: string
  hasAttribution: boolean
  name: string
  groups?: Array<GroupType>
}

type BaseDataType = {
  _id: string
  checked: boolean
  hidden: boolean
}
type DataType = BaseDataType & EmployeeType
type DataTableType = {
  leftList: { allChecked: boolean; data: DataType[] }
  rightList: { allChecked: boolean; data: DataType[] }
}

export const useExternalBenefitAssignToEmployees = ({
  externalBenefitId,
  setEmployeesToAssign,
}: {
  externalBenefitId: string
  setEmployeesToAssign: (employees: { _id: string }[]) => void
}) => {
  const { trackEvent } = useEventTracking()
  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()

  const [leftListData, setLeftListData] = useState<DataType[]>([])

  const [rightListData, setRightListData] = useState<DataType[]>([])

  const [employeesWithBenefitAssigned, setEmployeesBenefitAssigned] = useState<
    DataType[]
  >([])

  const { isFetching: isLoadingEmployees } = trpc.findEmployees.useQuery(
    { filter: { companyId } },
    {
      onSuccess: ({ employees: allEmployees }) => {
        const employees = allEmployees.map(employee => {
          return {
            ...employee,
            hasAttribution:
              employee.benefits?.some(({ id }) => id === externalBenefitId) ??
              false,
          }
        })

        setLeftListData(
          employees
            .filter(employee => !employee.hasAttribution)
            .map(employee => ({
              ...employee,
              checked: false,
              hidden: false,
            }))
        )

        const employeesAssigned = employees
          .filter(employee => employee.hasAttribution)
          .map(employee => ({
            ...employee,
            checked: false,
            hidden: false,
          }))

        setEmployeesBenefitAssigned(employeesAssigned)
        setRightListData(employeesAssigned)
      },
      queryKey: ['findEmployees', { filter: { companyId } }],
      refetchOnWindowFocus: false,
    }
  )

  const employeeMatchesSearchParam = ({
    employee,
    searchParam,
  }: {
    employee: DataType
    searchParam: string
  }) =>
    (employee?.name).match(new RegExp(searchParam, 'i')) ||
    (employee?.cpf)
      .replace(/[,\.-]/, '')
      .match(searchParam.replace(/[,\.-]/, ''))

  const handleLeftSearchEmployees = (searchParam = '') => {
    setLeftListData(
      leftListData.map(employee => {
        if (employeeMatchesSearchParam({ employee, searchParam })) {
          set(employee, 'hidden', false)
        } else {
          set(employee, 'hidden', true)
        }

        return employee
      })
    )

    trackEvent({ name: EVENTS.SEARCH_EMPLOYEES })
  }

  const handleRightSearchEmployees = (searchParam = '') => {
    setRightListData(
      rightListData.map(employee => {
        if (employeeMatchesSearchParam({ employee, searchParam })) {
          set(employee, 'hidden', false)
        } else {
          set(employee, 'hidden', true)
        }

        return employee
      })
    )

    trackEvent({ name: EVENTS.SEARCH_EMPLOYEES })
  }

  const handleTransferEmployee = (dataTables: DataTableType) => {
    const { leftList, rightList } = dataTables

    const updatedLeftList = [...leftList.data.filter(employee => !employee.hasAttribution)]

    setLeftListData(updatedLeftList)

    const updatedRightList = rightList.data.filter(
      employee => !employee.hasAttribution
    )
    setRightListData([...employeesWithBenefitAssigned, ...updatedRightList])

    setEmployeesToAssign(
      rightList.data.filter(({ hasAttribution }) => !hasAttribution)
    )

    trackEvent({ name: EVENTS.TRANSFER_EMPLOYEES })
  }

  const handleLeftCheck = ({
    allChecked,
    data,
  }: {
    allChecked?: boolean
    data: DataType[]
  }) =>
    setLeftListData(
      data.map(row => ({
        ...row,
        checked: allChecked ?? row.checked,
      }))
    )

  const handleRightCheck = ({
    allChecked,
    data,
  }: {
    allChecked?: boolean
    data: DataType[]
  }) =>
    setRightListData(
      data.map(row => ({
        ...row,
        checked: allChecked ?? row.checked,
      }))
    )

  return {
    handleLeftCheck,
    handleLeftSearchEmployees,
    handleRightCheck,
    handleRightSearchEmployees,
    handleTransferEmployee,
    isLoadingEmployees,
    leftListData,
    rightListData,
  }
}
