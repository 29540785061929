import { NavigateFunction } from 'react-router-dom'
import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'
import Space from '@components/Space'

import {
  ImageStyled,
  StyledContainer,
  StyledEmptyStateButton,
  StyledImageContainer,
  StyledTextContainer,
} from './styled'

export const ExternalBenefitEmptyStateBanner = ({
  navigate,
}: {
  navigate: NavigateFunction | undefined
}) => {
  const theme = useTheme()

  return (
    <StyledContainer>
      <StyledTextContainer>
        <ImageStyled
        // eslint-disable-next-line max-len
          src="https://images.flashapp.com.br/Front-Assets/Images/ExternalBenefit/flash_encanta_logo_empty_state.png"
        />
        <Space y="xs2" />
        <Typography
          variant="headline6"
          variantColor={theme.colors.neutral[100]}
          weight={700}
        >
        Multiplique os benefícios da sua <br /> empresa
        </Typography>
        <Space y="xs2" />
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[100]}
          weight={400}
        >
          Descubra o marketplace que multiplica os benefícios da sua <br/>
          empresa e dos colaboradores, simplificando sua rotina de <br />
          trabalho com parceiros imperdíveis.
        </Typography>
        <Space y="xs" />
        <StyledEmptyStateButton
          size="medium"
          variant="secondary"
          onClick={() => navigate && navigate('/marketplace')}
        >
          Quero conhecer
        </StyledEmptyStateButton>
      </StyledTextContainer>
      <StyledImageContainer>
        <ImageStyled
        // eslint-disable-next-line max-len
          src="https://images.flashapp.com.br/Front-Assets/Images/ExternalBenefit/flash_encanta_banner_empty_state.png"
        />
      </StyledImageContainer>

    </StyledContainer>
  )
}
