import {
  Button as HROSButton,
  IconTypes,
  Icons,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'

type ButtonProps = {
  title: string
  iconName: IconTypes
  disabled?: boolean
  onClick?(): void
}

export function Button({
  title,
  iconName,
  disabled = false,
  onClick,
}: ButtonProps) {
  const theme = useTheme()

  const getIconColor = useMemo(() => {
    return disabled ? theme.colors.neutral[70] : theme.colors.neutral[100]
  }, [disabled, theme.colors.neutral])

  return (
    <HROSButton
      size="large"
      variant="primary"
      disabled={disabled}
      onClick={onClick}
    >
      {title} <Icons name={iconName} color={getIconColor} />
    </HROSButton>
  )
}
