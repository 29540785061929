import FlexBoxItem, { FlexBoxItemProps } from './FlexBoxItem'
import styled, { type DefaultTheme, css } from 'styled-components'

export interface FlexBoxProps {
  alignItems?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch'
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse'
  gap?: keyof DefaultTheme['spacings']
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
    | 'stretch'
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  width?: string
  children: React.ReactNode | React.ReactElement<FlexBoxItemProps>
}

const FlexBoxStyled = styled.div<FlexBoxProps>`
  display: flex;

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}

  ${({ gap, theme }) =>
    gap &&
    css`
      gap: ${theme.spacings[gap]};
    `}
    
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`

const FlexBox = ({ children, ...otherProps }: FlexBoxProps) => {
  return <FlexBoxStyled {...otherProps}>{children}</FlexBoxStyled>
}

FlexBox.Item = FlexBoxItem

export default FlexBox
