export const emojiDefault =
  'https://images.flashapp.com.br/Front-Assets/Emoji/pencil.png'
export const emojiS3Urls = [
  'https://images.flashapp.com.br/Front-Assets/Emoji/employee-birthday.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/awards.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/happy-face.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/cool-face.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/red-heart.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/blue-heart.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/star-eyes.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/party.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/christmas.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/hamburger.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/glowing-star.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/grinning-face.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/face-with-monocle.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/facepunch.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/champagne-bottle.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/books.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/dollar_bills.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/candy.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/home.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/medical-symbol.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/meditation.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/car.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/eyes.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/card-swap-pink.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/clipboard.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/video-game.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/concert-ticket.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/email.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/envelope-with-arrow.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/emoji-homes.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/apple_logo.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/error.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/female-office-worker.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/fork-knife-plate.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/gear.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/globe-meridians.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/high-voltage.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/hourglass.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/inbox.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/key.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/left-right-arrow.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/link.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/locked.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/loudly-crying-face.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/male-office-worker.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/mobile-phone.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/money-bag.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/money-mouth-face.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/money-with-wings.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/palms-up-together.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/peace-sign.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/pencil.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/performing-arts.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/phone-arrow.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/phone-key.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/phone.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/popcorn.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/rabbit.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/race-car.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/recharge-success.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/rocket.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/runner.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/shopping-bags.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/shopping-cart.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/silhouette.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/smile.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/spiral-calendar.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/store.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/thinking-face.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/thumbs-down.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/thumbs-up.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/transport.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/unlocked.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/warning.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/winking-face.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/worker.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/worried-face.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/chair.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/birthday-cake.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/trophy.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/yellow-heart.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/gift.png',
  'https://images.flashapp.com.br/Front-Assets/Emoji/baby.png',
]
