import styled from 'styled-components'

import { Skeleton, Typography } from '@flash-tecnologia/hros-web-ui-v2'

export const MainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) =>
    `${theme.breakpoints.values.md}px`}) {
    text-align: center;
  }
`

export const LeftContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 315px;

  @media screen and (max-width: ${({ theme }) =>
    `${theme.breakpoints.values.md}px`}) {
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
  }
`

export const RightContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  margin-bottom: 32px;
`

export const SecondaryTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const BodyStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const HeadlineStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

export const ContentWrapperStyled = styled.div`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const SkeletonStyled = styled(Skeleton)`
  margin-top: ${({ theme }) => theme.spacings.m};
  height: 118px;
  width: 100%;
`

export const HeaderContainerStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.xs};
  width: 100%;
`
