import { EmojiContainer, EmojiLabelStyled, NameContainerStyled } from './styled'
import { emojiDefault } from '@components/EmojiPicker/constants'

type EmojiNameTabType = {
  name?: string
  emoji?: string
}

export const EmojiNameTab = ({ emoji, name }: EmojiNameTabType) => {
  const useEmoji = emoji || emojiDefault
  return (
    <NameContainerStyled>
      <EmojiContainer>
        <EmojiLabelStyled
          src={useEmoji}
          alt={useEmoji.split('/').pop()?.split('.png')?.[0]}
        />
      </EmojiContainer>
      <text>{name}</text>
    </NameContainerStyled>
  )
}
