import { Radio, Table, Typography } from '@flash-tecnologia/hros-web-ui-v2'

import { TableContainerStyled } from './styled'
import { useChooseExternalBenefit } from './useChooseExternalBenefit'
import { useTheme } from 'styled-components'
import ExternalBenefitItem from '@components/ExternalBenefitItem'

const ChooseExternalBenefit = ({ externalBenefitId, setExternalBenefitId }) => {
  const {
    benefits,
    fetchData,
    handleSearchOnFocus,
    handleSelectBenefit,
    isLoading,
    selected,
  } = useChooseExternalBenefit({ externalBenefitId, setExternalBenefitId })
  const theme = useTheme()

  return (
    <TableContainerStyled>
      <Typography variantColor={theme.colors.neutral[30]} variant="headline8">
        Benefícios
      </Typography>

      <Table.Root>
        <Table.Search
          onFocus={handleSearchOnFocus}
          label="Buscar por benefício"
          onChange={e => {
            fetchData({
              searchParam: e?.target?.value ?? '',
            })
          }}
        />
        <Table.Grid
          loading={isLoading}
          rowIdKey="id"
          options={{}}
          pageSize={1000}
          hasPagination={false}
          data={benefits?.filter(({ isAssignable }) => isAssignable) || []}
          columns={[
            {
              Cell: ({
                row,
              }: {
                row?: {
                  original: { id: string }
                }
              }) => (
                <Radio
                  key={row?.original.id}
                  value={row?.original.id}
                  checked={selected === row?.original.id}
                  onChange={e => handleSelectBenefit(e.target.value)}
                />
              ),
              accessor: 'id',
              disableSortBy: true,
            },
            {
              Cell: ({
                row,
              }: {
                row?: {
                  values: { name: string }
                  original: {
                    emoji: string
                    description: string
                    name: string
                  }
                }
              }) => {
                return (
                  <ExternalBenefitItem
                    name={row?.original.name}
                    description={row?.original?.description}
                    emoji={row?.original?.emoji}
                  />
                )
              },
              Header: 'Benefício',
              accessor: 'name',
              disableSortBy: true,
            },
          ]}
        />
      </Table.Root>
    </TableContainerStyled>
  )
}

export default ChooseExternalBenefit
