/* eslint-disable array-element-newline */
import { ERROR_MESSAGES } from '@utils/consts/errorMessages'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { trpc } from '@api/client'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import dispatchToast from '@utils/dispatchToast.utils'

type UseExternalBenefitTableArgs = {
  navigate: NavigateFunction | undefined
}

export const useExternalBenefitTable = ({
  navigate,
}: UseExternalBenefitTableArgs) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const effectiveNavigate = navigate || useNavigate()

  const theme = useTheme()

  const [translation] = useTranslation('translations', {
    keyPrefix: 'listExternalBenefits',
  })
  const {
    selectedCompany: { id },
  } = useSelectedCompany()
  const companyId = id as string
  const [filter] = useState({ companyId })
  const [pagination, setPagination] = useState({
    index: 0,
    pageNumber: 1,
    pageSize: 10,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [sort, setSort] = useState({ name: 1 })
  const [
    isDeleteBenefitConfirmationModalVisible,
    setIsDeleteBenefitConfirmationModalVisible,
  ] = useState(false)
  const [selectedBenefitId, setSelectedBenefitId] = useState<string>()
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  const { data, isFetching, refetch } = trpc.findExternalBenefits.useQuery(
    {
      filter,
      pagination: {
        limit: pagination.pageSize,
        skip: pagination.pageSize * (pagination.pageNumber - 1),
      },
      searchTerm,
      sort,
    },
    {
      onSuccess: () => {
        setIsInitialLoading(false)
      },
      queryKey: [
        'findExternalBenefits',
        {
          filter,
          pagination: {
            limit: pagination.pageSize,
            skip: pagination.pageSize * (pagination.pageNumber - 1),
          },
          searchTerm,
          sort,
        },
      ],
      refetchOnWindowFocus: false,
    }
  )

  const {
    mutate: deleteExternalBenefitMutation,
    isLoading: isDeleteExternalBenefitLoading,
  } = trpc.deleteExternalBenefit.useMutation({
    onError: error => {
      dispatchToast({
        content: error?.message,
        type: 'error',
      })
    },
    onSuccess: () => {
      dispatchToast({
        content: 'Benefício excluído com sucesso!',
        type: 'success',
      })
      refetch()
      closeDeleteBenefitConfirmationModal()
    },
  })

  const totalBenefits = data?.total ?? 0
  const benefits =
    data?.benefits.map(benefit => ({
      ...benefit,
      description: benefit.description || '',
    })) || []

  const setParams = input => {
    const newSort = input.sortBy?.reduce((acc, sortBy) => {
      return { ...acc, [sortBy.id]: sortBy?.desc ? -1 : 1 }
    }, {})
    setSort(newSort)
  }

  const setSearch = searchTerm => {
    setPagination(currentPagination => ({
      ...currentPagination,
      pageNumber: 1,
    }))

    setSearchTerm(searchTerm)
  }

  const openDeleteBenefitConfirmationModal = (benefitId: string) => {
    const selectedBenefit = benefits.find(({ id }) => benefitId === id)
    if (!selectedBenefit?.isEditable) {
      dispatchToast({
        content: ERROR_MESSAGES.BENEFIT_DELETE_FORBIDDEN,
        type: 'error',
      })
      return
    }
    setSelectedBenefitId(benefitId)
    setIsDeleteBenefitConfirmationModalVisible(true)
  }

  const closeDeleteBenefitConfirmationModal = () => {
    setIsDeleteBenefitConfirmationModalVisible(false)
    setSelectedBenefitId(undefined)
  }

  const submitDeleteBenefitConfirmationModal = () => {
    if (selectedBenefitId) {
      deleteExternalBenefitMutation({ companyId, id: selectedBenefitId })
    }
  }

  const showInitialEmptyState = !isFetching && !searchTerm && !benefits?.length

  return {
    benefits,
    closeDeleteBenefitConfirmationModal,
    effectiveNavigate,
    isDeleteBenefitConfirmationModalVisible,
    isDeleteExternalBenefitLoading,
    isFetching,
    isInitialLoading,
    openDeleteBenefitConfirmationModal,
    pagination,
    refetch,
    setIsDeleteBenefitConfirmationModalVisible,
    setPagination,
    setParams,
    setSearch,
    setSearchTerm,
    showInitialEmptyState,
    submitDeleteBenefitConfirmationModal,
    theme,
    totalBenefits,
    translation,
  }
}
