import styled from 'styled-components'

const ContentGridContainerStyled = styled.div`
  column-gap: ${({ theme }) => theme.spacings.xs};
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);
  overflow: scroll;
  row-gap: ${({ theme }) => theme.spacings.xs2};
  width: 100%;
`

const LeftPanelContainerStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
`

const RightPanelContainerStyled = styled.div`
  border-radius: ${({ theme }) => theme.spacings.xs4};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.s};
  display: grid;
  grid-column-start: 4;
  grid-column-end: 12;
  gap: ${({ theme }) => theme.spacings.xs};
`

export {
  ContentGridContainerStyled,
  LeftPanelContainerStyled,
  RightPanelContainerStyled,
}
