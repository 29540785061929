import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient, trpc, trpcClient } from '@api/client'
import ExternalBenefitTable from '@components/ExternalBenefitTable'

const ExternalBenefitTab = ({ navigate = undefined }) => {
  return (
    <>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <ExternalBenefitTable navigate={navigate} />
        </QueryClientProvider>
      </trpc.Provider>
    </>
  )
}

export default ExternalBenefitTab
