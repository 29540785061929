import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContentGridContainerStyled = styled.div`
  column-gap: ${({ theme }) => theme.spacings.xs};
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);
  height: 100%;
  overflow: scroll;
  row-gap: ${({ theme }) => theme.spacings.xs2};
  width: 100%;
`

export const LeftPanelContainerStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
`

export const PanelTitleStyled = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
`

export const RightPanelContainerStyled = styled.div`
  display: grid;
  grid-column-start: 4;
  grid-column-end: 12;
`
