import styled from 'styled-components'

export const EmojiContainer = styled.div`
  display: flex;
  place-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.icons.sizes.l};
  width: ${({ theme }) => theme.icons.sizes.l};
  background-color: darkgreen;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 50%;
  gap: ${({ theme }) => theme.spacings.xs4};
`

export const NameContainerStyled = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`

export const EmojiLabelStyled = styled.img`
  margin: ${({ theme }) => theme.spacings.s};
  height: ${({ theme }) => theme.icons.sizes.xs};
  width: ${({ theme }) => theme.icons.sizes.xs};
`
