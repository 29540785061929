import styled from 'styled-components'

const LoadingContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const TextInLineStyled = styled.div`
  inline-size: max-content;
`

export { FiltersContainer, LoadingContainerStyled, TextInLineStyled }
