import { ContainerStyled, LinkButtonStyled } from './styled'
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'
import EmptyStateSVG from '../../assets/empty-state.svg'
import Space from '@components/Space'

const EmptyState = ({
  buttonAriaLabel,
  buttonLabel,
  description,
  handleButtonClick,
  title,
}) => {
  const theme = useTheme()

  return (
    <ContainerStyled>
      <EmptyStateSVG aria-hidden="true" />
      <Space y={'xs'} />
      <Typography variant="headline6" variantColor={theme.colors.neutral[20]}>
        {title}
      </Typography>
      <Space y={'xs2'} />
      <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
        {description}
      </Typography>
      <Space y={'xs2'} />
      <LinkButtonStyled
        variant="primary"
        onClick={handleButtonClick}
        aria-label={buttonAriaLabel}
      >
        {buttonLabel} <Icons name="IconPlus" />
      </LinkButtonStyled>
    </ContainerStyled>
  )
}

export default EmptyState
