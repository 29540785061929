import { ThemesType, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import ModalActions from '@components/ModalActions'
import Space from '@components/Space'

type SendingReportModalType = {
  email: string
  isOpen: boolean
  onClose: () => void
  theme: ThemesType
}

const SendingReportModal = ({
  email,
  isOpen,
  onClose,
  theme,
}: SendingReportModalType) => {
  return (
    <ModalActions
      hasCancelButton={false}
      type="success"
      subtitle="Pronto!"
      iconHeader="IconCheck"
      isOpen={isOpen}
      loading={false}
      title="Sua planilha está sendo exportada!"
      description={
        <>
          <Space x="xs" />
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            Para acessá-la é só conferir o e-mail que será enviado em alguns
            minutos para o endereço:
          </Typography>
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[50]}
            weight={700}
          >
            {email}
          </Typography>
          <Space y="xs1" />
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            Para acessar os dados utilize os
            <strong> 4 primeiros dígitos do seu CPF </strong>
            com senha.
          </Typography>
        </>
      }
      submitText="Voltar para Atribuições"
      onClose={onClose}
      onSubmit={onClose}
    />
  )
}

export default SendingReportModal
