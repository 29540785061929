import * as Header from '@components/Header'
import {
  Accordion,
  Button,
  Checkbox,
  Focused,
  IconButton,
  Icons,
  Table,
  Tag,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import {
  ActionsContainerStyled,
  ContainerStyled,
  HeaderContainerStyled,
  TextInLineStyled,
} from './styled'
import { ChoiceInformation } from './components/ChoiceInformation'
import { Title } from '@components/Title'
import {
  benefitAssignStatus,
  benefitChooseStatus,
  choiceStatus,
  tooltipBenefitsInfo,
} from './consts'
import { useChoiceDetails } from './useChoiceDetails'
import EmployeeItem from '@components/EmployeeItem'
import Space from '@components/Space'

const TooltipInfo = () => (
  <Tooltip
    placement="top-end"
    title={tooltipBenefitsInfo.map(info => (
      <ContainerStyled>
        <Tag variant={info.tagColor} hasLeftDotIcon={true} disabled={true}>
          {info.tagText}
        </Tag>
        <Typography variant="body4" weight={600}>
          {info.description}
        </Typography>
        <Space y="xs4" />
      </ContainerStyled>
    ))}
  >
    <span>
      <Icons name="IconInfoCircle" width={16} height={16} />
    </span>
  </Tooltip>
)

const ChoiceDetails = () => {
  const {
    accordionDescription,
    choice: { benefitNames, description, name },
    choiceId,
    employees,
    handleDisabledAppliedButton,
    isLoading,
    navigate,
    theme,
  } = useChoiceDetails()

  const columns = [
    {
      Cell: () => <Checkbox checked={false} onChange={() => ({})} />,
      Header: <Checkbox checked={false} onChange={() => ({})} />,
      accessor: 'cpf',
      disableSortBy: true,
    },
    {
      Cell: ({ row }) => (
        <TextInLineStyled>
          <EmployeeItem name={row?.original?.name} cpf={row?.original?.cpf} />
        </TextInLineStyled>
      ),
      Header: 'Nome',
      accessor: 'name',
      customSortKey: 'name',
      disableSortBy: false,
    },
    {
      Cell: ({ row }) => (
        <TextInLineStyled>
          <Tag
            disabled={true}
            variant={choiceStatus(row?.original?.status)}
            hasLeftDotIcon={true}
          >
            {row?.original?.status}
          </Tag>
        </TextInLineStyled>
      ),
      Header: 'Status',
      accessor: 'status',
      customSortKey: 'status',
      disableSortBy: true,
    },
    {
      Cell: ({ row }) => (
        <ContainerStyled>
          <Icons name="IconCalendarEvent" width={24} height={24} />
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[40]}
            weight={600}
          >
            {row?.original?.choosePeriod}
          </Typography>
        </ContainerStyled>
      ),
      Header: (
        <HeaderContainerStyled>
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral[40]}
          >
            Prazo para escolha
          </Typography>
          <Tooltip
            placement="top-end"
            title={`A data inicial se refere ao dia da última comunicação enviada,
            e a data final se refere ao último dia definido para a pessoa fazer a escolha`}
          >
            <span>
              <Icons name="IconInfoCircle" width={16} height={16} />
            </span>
          </Tooltip>
        </HeaderContainerStyled>
      ),
      accessor: 'choosePeriod',
      customSortKey: 'choosePeriod',
      disableSortBy: true,
    },
    {
      Cell: ({ row }) => (
        <TextInLineStyled>
          <Tag
            disabled={true}
            variant={benefitChooseStatus(row?.original?.status)}
            hasLeftDotIcon={true}
          >
            {row?.original?.chosen}
          </Tag>
        </TextInLineStyled>
      ),
      Header: (
        <TextInLineStyled>
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral[40]}
          >
            Benefício escolhido
          </Typography>
        </TextInLineStyled>
      ),
      accessor: 'chosen',
      customSortKey: 'chosen',
      disableSortBy: true,
    },
    {
      Cell: ({ row }) => (
        <ContainerStyled>
          <Icons name="IconCalendarEvent" width={24} height={24} />
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[40]}
            weight={600}
          >
            {row?.original?.chosenAt}
          </Typography>
        </ContainerStyled>
      ),
      Header: 'Data da escolha',
      accessor: 'chosenAt',
      customSortKey: 'chosenAt',
      disableSortBy: true,
    },
    {
      Cell: ({ row }) => (
        <HeaderContainerStyled>
          <Tag
            disabled={true}
            variant={benefitAssignStatus(
              row?.original?.status,
              row?.original?.assignedBenefits
            )}
            hasLeftDotIcon={true}
          >
            {row?.original?.assignedBenefits}
          </Tag>
          <TooltipInfo />
        </HeaderContainerStyled>
      ),
      Header: (
        <HeaderContainerStyled>
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral[40]}
          >
            Benefício atribuído
          </Typography>
          <TooltipInfo />
        </HeaderContainerStyled>
      ),
      accessor: 'assignedBenefits',
      customSortKey: 'assignedBenefits',
      disableSortBy: true,
    },
    {
      Cell: ({ row }) => (
        <ContainerStyled>
          <Icons name="IconCalendarEvent" width={24} height={24} />
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[40]}
            weight={600}
          >
            {row?.original?.assignedAt}
          </Typography>
        </ContainerStyled>
      ),
      Header: (
        <TextInLineStyled>
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral[40]}
          >
            Data da atribuição
          </Typography>
        </TextInLineStyled>
      ),
      accessor: 'assignedAt',
      customSortKey: 'assignedAt',
      disableSortBy: true,
    },
    {
      Header: 'Grupo',
      accessor: 'group',
      customSortKey: 'group',
      disableSortBy: true,
    },
    {
      Cell: ({ row }) => (
        <ActionsContainerStyled>
          <Button
            onClick={() => ({})}
            variant="secondary"
            size="small"
            disabled={handleDisabledAppliedButton(row?.original?.status)}
          >
            Aplicar <Icons name="IconUserCheck" />
          </Button>
          <IconButton
            aria-label="Excluir pessoa do benefício"
            icon="IconDotsVertical"
            onClick={() => ({})}
            variant="line"
            size="small"
          />
        </ActionsContainerStyled>
      ),
      Header: (
        <ActionsContainerStyled>
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral[40]}
          >
            Aplicar
          </Typography>
          <Tooltip
            placement="top-end"
            title={
              'Os benefícios escolhidos só passam a ser atribuídos depois de aplicados.'
            }
          >
            <span>
              <Icons name="IconInfoCircle" width={16} height={16} />
            </span>
          </Tooltip>
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral[40]}
          >
            Ações
          </Typography>
        </ActionsContainerStyled>
      ),
      accessor: 'id',
      disableSortBy: true,
      sticky: 'right',
    },
  ]

  return (
    <Focused
      contentWrapperStyle={{ gap: theme.spacings.s }}
      footer={{}}
      navigate={navigate}
      routes={[
        {
          label: 'Atribuição de benefícios',
          route: '/simple-assignment',
        },
        {
          label: 'Escolhas',
          route: '/simple-assignment/benefit-choices',
        },
        {
          label: 'Detalhes da escolha',
        },
      ]}
    >
      <Space x="s" />
      <Header.Root>
        <Header.Text theme={theme} title={name} />
        <Header.Button
          title="Adicionar pessoas"
          iconName="IconUserPlus"
          onClick={() =>
            navigate('/benefit-choices/assign-employees-to-choice', {
              state: { choiceId },
            })
          }
        />
      </Header.Root>
      <Accordion
        variant="default"
        title={name}
        description={accordionDescription}
        actions={[
          {
            icon: 'IconPencil',
            onClick: () => {
              return
            },
          },
        ]}
      >
        <ChoiceInformation
          benefitNames={benefitNames}
          description={description}
          name={name}
          theme={theme}
        />
      </Accordion>
      <Table.Root>
        <Title
          title="Pessoas selecionadas para esta escolha"
          totalCount={employees?.employees.length}
          loading={false}
        />
        <Table.Search label="Buscar por pessoas" onChange={() => ({})} />
        <Table.Grid
          columns={columns}
          data={employees?.employees || []}
          loading={isLoading}
          options={{}}
          pageSize={10}
          rowIdKey=""
          emptyState={{
            children: (
              <Button
                aria-label="Adicionar pessoas à escolha"
                size="large"
                variant="primary"
                onClick={() =>
                  navigate('/benefit-choices/assign-employees-to-choice', {
                    state: { choiceId },
                  })
                }
              >
                Adicionar pessoas <Icons name="IconUserPlus" />
              </Button>
            ),
            message: 'Você ainda não adicionou pessoas a esta escolha',
          }}
        />
        <Table.Pagination
          onPaginationChange={() => {
            return
          }}
          count={0}
          pagination={{
            index: 1,
            pageNumber: 0,
            pageSize: 10,
          }}
        />
      </Table.Root>
    </Focused>
  )
}

export default ChoiceDetails
