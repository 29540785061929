import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

const ContainerStyled = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs} 112px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ContainerButtonStyled = styled(ContainerStyled)`
  justify-content: center;
`

const LinkButtonStyled = styled(LinkButton)`
  &.linkButton-custom-theme.MuiPaper-root.MuiButtonBase-root.secondary {
    align-self: center;
  }
`

export { ContainerStyled, LinkButtonStyled, ContainerButtonStyled }
