import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

const BenefitItemContainerStyled = styled.div`
  align-items: center;
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs3};
  grid-template-columns: 56px 7fr;
`

const BenefitEmojiContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  padding: ${({ theme }) => theme.spacings.xs2};

  img {
    width: ${({ theme }) => theme.spacings.xs};
    height: ${({ theme }) => theme.spacings.xs};
  }
`

const BenefitDescriptionContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const BenefitDescriptionStyled = styled(Typography)`
  color: #83727d;
`

export {
  BenefitItemContainerStyled,
  BenefitEmojiContainerStyled,
  BenefitDescriptionContainerStyled,
  BenefitDescriptionStyled,
}
