import styled from 'styled-components'

const ContainerListStyled = styled.div`
  border-radius: ${({ theme }) => theme.spacings.xs4};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.s};
  height: ${({ theme }) => theme.breakpoints.values.lg}px;
`

export { ContainerListStyled }
