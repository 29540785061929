import styled from 'styled-components'

const ContainerStyled = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`

const SubinfoStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
  cursor: default;
`

export { ContainerStyled, SubinfoStyled }
