import { ERROR_MESSAGES } from '@utils/consts/errorMessages'
import { EVENTS } from '@utils/consts/tracking'
import { dayjs } from '@utils/dayjs'
import {
  getAccessTokenPayloadSync,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'
import { trpc } from '@api/client'
import { useDispatchToast } from '@utils/hooks/useDispatchToast'
import { useEffect, useState } from 'react'
import { useEventTracking } from '@utils/hooks/useEventTracking'

type Employee = {
  _id: string
  name: string
  cpf: string
  attributionDate: string
}

type AdminType = {
  id: string
  name: string
  documentNumber?: string
  email?: string
}

type Benefit = {
  id: string
  description?: string
  name: string
  emoji: string
}

export const useExternalBenefitAssignments = ({ navigate }) => {
  const { trackEvent } = useEventTracking()
  const { dispatchToast } = useDispatchToast()
  const {
    selectedCompany: { id: companyId },
    selectedCompany: company,
  } = useSelectedCompany()
  const { employeeId: adminId } = getAccessTokenPayloadSync()
  const [admin, setAdmin] = useState<AdminType>()
  const [selectedBenefit, setSelectedBenefit] = useState<Benefit>()
  const [searchParam, setSearchParam] = useState<string>('')
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenReportModal, setIsOpenReportModal] = useState(false)
  const [isOpenReadyModal, setIsOpenReadyModal] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchEmployeesParam, setSearchEmployeesParam] = useState<string>('')
  const [employees, setEmployees] = useState<Employee[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [employeesToUnassign, setEmployeesToUnassign] = useState<
    { id: string }[] | []
  >([])
  const [allSelected, setAllSelected] = useState(false)
  const [selectedEmployees, setSelectedEmployees] = useState(new Set())
  const [selectHeader, setSelectHeader] = useState(false)
  const [reportBenefitId, setReportBenefitId] = useState<string>()

  const {
    data: getAllExternalBenefitData,
    isLoading: isGetAllExternalBenefitsLoading,
    refetch: refetchExternalBenefits,
  } = trpc.getAllExternalBenefit.useQuery(
    { companyId, searchParam },
    {
      cacheTime: 0,
      onSuccess: data => {
        if (data?.benefits?.[0]) setSelectedBenefit(data.benefits[0])
        if (!initialLoading) {
          return setInitialLoading(false)
        }
        setInitialLoading(true)
      },
      refetchOnWindowFocus: false,
    }
  )

  const { refetch: refetchAdmin } = trpc.getAdmin.useQuery(
    { adminId },
    {
      enabled: false,
      onError: () => {
        dispatchToast({
          description: 'Tente novamente mais tarde.',
          title: 'Erro ao carregar as informações do administrador.',
          type: 'error',
        })
      },
      onSuccess: data => {
        if (!data?.email) {
          dispatchToast({
            description: 'E-mail não encontrado.',
            title: 'Por favor, cadastre um e-mail para exportar a atribuição.',
            type: 'error',
          })
          return
        }
        setAdmin(data)
        setIsOpenReportModal(true)
      },
      queryKey: ['getAdmin', { adminId }],
      refetchOnWindowFocus: false,
    }
  )

  const generateExternalBenefitReportMutation =
    trpc.generateExternalBenefitReport.useMutation({
      onError: error => {
        dispatchToast({
          description: error?.message,
          title: 'Erro ao exportar atribuição',
          type: 'error',
        })
      },
      onSuccess: () => {
        setIsOpenReportModal(false)
        setIsOpenReadyModal(true)
        setAllSelected(false)
        setSelectedEmployees(new Set())
      },
    })

  const { refetch: refetchEmployees, isLoading: isEmployeesLoading } =
    trpc.findEmployees.useQuery(
      {
        filter: {
          companyId,
          externalBenefitId: selectedBenefit?.id,
        },
        pagination: {
          limit: pageSize,
          skip: (pageNumber - 1) * pageSize,
        },
        searchTerm: searchEmployeesParam,
      },
      {
        enabled: false,
        onSuccess: data => {
          const formattedEmployees = data.employees.map(
            ({ id, name, documentNumber, benefits }) => {
              const attributionDate = benefits?.find(
                ({ id }) => id === selectedBenefit?.id
              )?.attributionDate
              return {
                _id: id,
                attributionDate: attributionDate
                  ? dayjs(attributionDate).format('DD/MM/YYYY')
                  : '-',
                cpf: documentNumber,
                name,
              }
            }
          )
          setEmployees(formattedEmployees)
          setPageCount(Math.ceil(data.total / pageSize))
          setTotalItems(data.total)
        },
        queryKey: [
          'findEmployees',
          {
            filter: {
              companyId,
              externalBenefitId: selectedBenefit?.id,
            },
          },
        ],
        refetchOnWindowFocus: false,
      }
    )

  useEffect(() => {
    if (selectedBenefit?.id) refetchEmployees()
  }, [
    refetchEmployees,
    selectedBenefit,
    searchEmployeesParam,
    pageSize,
    pageNumber,
  ])

  const unassignEmployeesExternalBenefitMutation =
    trpc.unassignEmployeesExternalBenefit.useMutation({
      onError: () => {
        setEmployeesToUnassign([])
        dispatchToast({
          description: 'Tente novamente mais tarde.',
          title: 'Erro ao excluir a pessoa',
          type: 'error',
        })
        setIsOpenDeleteModal(false)
      },
      onSuccess: () => {
        dispatchToast({
          content: 'Pessoa excluída com sucesso!',
          type: 'success',
        })
        setIsOpenDeleteModal(false)
        setAllSelected(false)
        setSelectHeader(false)
        setSelectedEmployees(new Set())
        setPageNumber(1)
        fetchEmployeesData({ pageNumber, pageSize, searchParam })
      },
    })

  const fetchBenefitsData = ({ searchParam = '' }) => {
    refetchExternalBenefits()
    setSearchParam(searchParam ?? '')
    setInitialLoading(false)
  }

  const fetchEmployeesData = ({
    pageNumber,
    pageSize,
    searchParam,
  }: {
    pageNumber?: number
    pageSize?: number
    searchParam?: string
  }) => {
    setPageNumber(pageNumber ? pageNumber : 1)
    setPageSize(pageSize ?? 10)
    setSearchEmployeesParam(searchParam ?? '')
    refetchEmployees({
      queryKey: ['findEmployees', { pageNumber, pageSize, searchParam }],
    })
  }

  const handleTabChanged = (index: number) => {
    setAllSelected(false)
    setSelectHeader(false)
    setEmployeesToUnassign([])
    setTotalItems(0)
    setSearchEmployeesParam('')
    setSelectedBenefit(getAllExternalBenefitData?.benefits?.[index])
    fetchEmployeesData({})
    trackEvent({ name: EVENTS.EXTERNAL_BENEFITS_TAB })
  }

  const handleNotFoundButtonClick = () => {
    trackEvent({
      name: EVENTS.REDIRECT_EXTERNAL_BENEFITS,
      params: {
        hasAttribuition: 'false',
        hasBenefits: 'false',
      },
    })
    navigate('/benefit-management/external-benefit')
  }

  const handleExternalBenefitAssign = (benefitId: string | undefined) => {
    trackEvent({
      name: EVENTS.REDIRECT_NEW_ASSIGN,
      params: {
        hasAttribuition: 'false',
        hasBenefits: 'false',
      },
    })

    const benefit = getAllExternalBenefitData?.benefits.find(
      ({ id }) => id === benefitId
    )

    if (!benefit?.isAssignable) {
      dispatchToast({
        content: ERROR_MESSAGES.BENEFIT_ASSIGNMENT_FORBIDDEN,
        type: 'error',
      })
      return
    }

    navigate('/external-benefit-simple-assign/new-assign', {
      state: { externalBenefitId: benefitId, step: '1' },
    })
  }

  const handleDeleteEmployeeAssign = (selected?: string[]): void => {
    const benefit = getAllExternalBenefitData?.benefits.find(
      ({ id }) => id === selectedBenefit?.id
    )

    if (!benefit?.isAssignable) {
      dispatchToast({
        content: ERROR_MESSAGES.UNASSIGNMENT_EMPLOYEE_FORBIDDEN,
        type: 'error',
      })
      return
    }

    if (selected?.length) {
      setEmployeesToUnassign(selected.map(employeeId => ({ id: employeeId })))
    }
    setIsOpenDeleteModal(true)
    trackEvent({ name: EVENTS.DELETE_EMPLOYEE_ASSIGN })
  }

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false)
    setAllSelected(false)
    setSelectHeader(false)
    setSelectedEmployees(new Set())
    setEmployeesToUnassign([])
  }

  const handleCloseSearch = () => {
    setSearchEmployeesParam('')
  }

  const handleSubmitDeleteModal = () => {
    if (selectedBenefit) {
      unassignEmployeesExternalBenefitMutation.mutate({
        benefitId: selectedBenefit?.id,
        companyId,
        employeeIds: employeesToUnassign.map(({ id }) => id),
        unassignAll: allSelected,
      })
    }
  }

  const handleOpenReportModal = (id: string | undefined): void => {
    refetchAdmin({ queryKey: ['getAdmin', { adminId }] })
    setReportBenefitId(id)
  }

  const handleCloseReportModal = () => {
    setIsOpenReportModal(false)
    setAllSelected(false)
    setReportBenefitId(undefined)
    setSelectedEmployees(new Set())
  }

  const handleCloseReadyModal = () => {
    setIsOpenReadyModal(false)
    setReportBenefitId(undefined)
  }

  const handleSubmitReportModal = () => {
    trackEvent({ name: EVENTS.EXPORT_EMPLOYEES_ASSIGN })
    generateExternalBenefitReportMutation.mutate({
      admin: {
        documentNumber: admin?.documentNumber || '',
        email: admin?.email || '',
        name: admin?.name || '',
      },
      benefitIds: reportBenefitId ? [reportBenefitId] : [],
      company: {
        cnpj: company?.registrationNumber || '',
        name: company?.name || '',
      },
      companyId,
      employeeIds: [],
    })
  }

  const handleSelect = (employeeId?: string) => {
    if (!employeeId || selectedEmployees.size === totalItems - 1) {
      setAllSelected(prev => !prev)
      setSelectHeader(prev => !prev)
      setSelectedEmployees(new Set())
      trackEvent({ name: EVENTS.CHECKALL_EMPLOYEES })
    } else {
      setSelectedEmployees(prev => {
        const newSet = new Set(prev)
        if (newSet.has(employeeId)) {
          newSet.delete(employeeId)
          setSelectHeader(false)
        } else {
          newSet.add(employeeId)
          if (selectedEmployees.size === totalItems - 1) {
            setSelectHeader(true)
          }
        }
        setEmployeesToUnassign(
          [...newSet].map((employeeId: string) => ({ id: employeeId }))
        )
        return newSet
      })
    }
  }

  const hasEmployeeSelected = allSelected || selectedEmployees.size

  return {
    adminEmail: admin?.email || '',
    allSelected,
    benefits: getAllExternalBenefitData?.benefits,
    employees,
    fetchData: fetchBenefitsData,
    fetchEmployeesData,
    handleCloseDeleteModal,
    handleCloseReadyModal,
    handleCloseReportModal,
    handleCloseSearch,
    handleDeleteEmployeeAssign,
    handleExternalBenefitAssign,
    handleNotFoundButtonClick,
    handleOpenReportModal,
    handleSelect,
    handleSubmitDeleteModal,
    handleSubmitReportModal,
    handleTabChanged,
    hasEmployeeSelected,
    initialLoading,
    isEmployeesLoading,
    isGetAllExternalBenefitsLoading,
    isOpenDeleteModal,
    isOpenReadyModal,
    isOpenReportModal,
    isUnassignEmployeesLoading:
      unassignEmployeesExternalBenefitMutation.isLoading,
    pageCount,
    pageNumber,
    pageSize,
    searchEmployeesParam,
    searchParam,
    selectHeader,
    selectedBenefit,
    selectedEmployees,
    submitReportLoading: generateExternalBenefitReportMutation.isLoading,
    totalItems,
  }
}
