import styled from 'styled-components'

const ContainerStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
  justify-content: flex-start;
`

const ActionsContainerStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.m};
  align-items: center;
  justify-content: center;
`

const HeaderContainerStyled = styled(ContainerStyled)`
  inline-size: max-content;
`

const TextInLineStyled = styled.div`
  inline-size: max-content;
`

export {
  ActionsContainerStyled,
  ContainerStyled,
  HeaderContainerStyled,
  TextInLineStyled,
}
