import { Navigate } from 'react-router-dom'
import {
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'

export enum PermissionEnum {
  EXTERNAL_BENEFIT_VIEW = 'benefits_external_benefits_view',
  EXTERNAL_BENEFIT_EDIT = 'benefits_external_benefits_edit',
  EXTERNAL_BENEFIT_ASSIGN_VIEW = 'benefits_external_benefits_assign_view',
  EXTERNAL_BENEFIT_ASSIGN_EDIT = 'benefits_external_benefits_assign_edit',
  CHOICE_VIEW = 'benefits_benefit_choices_view',
  CHOICE_EDIT = 'benefits_benefit_choices_edit',
}

type VerifyPermissionProps = {
  permission: PermissionEnum
  component: JSX.Element
}

export function VerifyPermission({
  permission,
  component,
}: VerifyPermissionProps) {
  const { companies, isAdmin } = usePermissions()
  const companyId = useSelectedCompany()?.selectedCompany?.id

  const companyPermissions =
    companies?.find(company => company.id === companyId)?.permissions ?? []
  const canView = companyPermissions.includes(permission)

  if (isAdmin || canView) return component
  return <Navigate to={'/home'} />
}
