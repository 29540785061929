import { trpc } from '@api/client'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'

type GroupType = {
  id: string
}
type EmployeeType = {
  _id: string
  cpf: string
  hasAttribution: boolean
  hasAttributionInOtherChoice: boolean
  name: string
  groups?: Array<GroupType>
}

type BaseDataType = {
  _id: string
  checked: boolean
  hidden: boolean
}
type DataType = Array<BaseDataType & Partial<EmployeeType>>
type DataTableType = {
  leftList: { allChecked: boolean; data: DataType }
  rightList: { allChecked: boolean; data: DataType }
}

export const useEmployeesList = ({ setSelectedEmployees }) => {
  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()
  const [leftSearchParam, setLeftSearchParam] = useState('')
  const [rightSearchParam, setRightSearchParam] = useState('')
  const [leftListData, setLeftListData] = useState<DataType>([])
  const [rightListData, setRightListData] = useState<DataType>([])

  const { isFetching: isLoadingEmployees } = trpc.findEmployees.useQuery(
    { filter: { companyId } },
    {
      onSuccess: ({ employees }: { employees: EmployeeType[] }) => {
        setLeftListData(
          employees.map(employee => {
            const hidden = employeeFilteredOut({
              employee: employee,
              searchParam: leftSearchParam,
            })

            return {
              ...employee,
              checked: false,
              hidden,
            }
          })
        )
      },
      queryKey: ['findEmployees', { filter: { companyId } }],
      refetchOnWindowFocus: false,
    }
  )

  const employeeFilteredOut = ({
    searchParam,
    employee,
  }: {
    searchParam: string
    employee: EmployeeType
  }) => {
    if (
      searchParam &&
      !employee.name.match(new RegExp(searchParam, 'i')) &&
      !employee.cpf
        .replace(/[,\.-]/, '')
        .match(searchParam.replace(/[,\.-]/, ''))
    ) {
      return true
    }
    return false
  }

  const handleLeftSearchEmployees = (searchParam = '') => {
    setLeftListData(
      leftListData.map(row => {
        const hidden =
          rightListData.some(({ _id }) => row._id === _id) ||
          employeeFilteredOut({
            employee: row as EmployeeType,
            searchParam,
          })

        return {
          ...row,
          hidden,
        }
      })
    )

    setLeftSearchParam(searchParam)
  }

  const handleRightSearchEmployees = (searchParam = '') => {
    setRightListData(
      rightListData.map(row => {
        const hidden = employeeFilteredOut({
          employee: row as EmployeeType,
          searchParam,
        })

        return {
          ...row,
          hidden,
        }
      })
    )

    setRightSearchParam(searchParam)
  }

  const handleTransferEmployee = (dataTables: DataTableType) => {
    const { leftList, rightList } = dataTables

    setLeftListData(
      leftList.data.map(row => {
        const hidden =
          rightList.data.some(({ _id }) => row._id === _id) ||
          employeeFilteredOut({
            employee: row as EmployeeType,
            searchParam: leftSearchParam,
          })

        return {
          ...row,
          hidden,
        }
      })
    )

    setRightListData(
      rightList.data.map(row => {
        const hidden =
          employeeFilteredOut({
            employee: row as EmployeeType,
            searchParam: rightSearchParam,
          }) ||
          row.hasAttributionInOtherChoice ||
          false

        return {
          ...row,
          hidden,
        }
      })
    )

    setSelectedEmployees(rightList.data.map(({ _id }) => ({ id: _id })))
  }

  const handleLeftCheck = ({
    allChecked,
    data,
  }: {
    allChecked?: boolean
    data: DataType
  }) =>
    setLeftListData(
      data.map(row => ({
        ...row,
        checked: allChecked ?? row.checked,
      }))
    )

  const handleRightCheck = ({
    allChecked,
    data,
  }: {
    allChecked?: boolean
    data: DataType
  }) =>
    setRightListData(
      data.map(row => ({
        ...row,
        checked: allChecked ?? row.checked,
      }))
    )

  return {
    handleLeftCheck,
    handleLeftSearchEmployees,
    handleRightCheck,
    handleRightSearchEmployees,
    handleTransferEmployee,
    isLoadingEmployees,
    leftListData,
    rightListData,
  }
}
