import { ExternalBenefitPage } from '../../components/ExternalBenefitPage/index'
import { RoutesProps } from '@flash-tecnologia/hros-web-ui-v2'
import { useCreateExternalBenefit } from './useCreateExternalBenefit'
import { useNavigate } from 'react-router-dom'

export const CreateExternalBenefit = () => {
  const {
    errorEmptyName,
    externalBenefit,
    handleCancel: onCancel,
    handleConfirm: onConfirm,
    isLoadingConfirm,
    onChangeInput,
    translation,
  } = useCreateExternalBenefit()
  const navigate = useNavigate()

  const breadcrumbsRoutes: RoutesProps[] = [
    {
      label: translation('companyBenefits'),
      onClick: () => navigate('/benefit-management/external-benefit'),
      route: '/benefit-management/external-benefit',
    },
    {
      label: translation('newExternalBenefit'),
    },
  ]

  return (
    <ExternalBenefitPage
      breadCrumbsRoutes={breadcrumbsRoutes}
      errorEmptyName={errorEmptyName}
      externalBenefit={externalBenefit}
      isLoading={false}
      isLoadingConfirm={isLoadingConfirm}
      onCancel={onCancel}
      onChangeInput={onChangeInput}
      onConfirm={onConfirm}
      title={translation('newExternalBenefit')}
      translation={translation}
    />
  )
}
