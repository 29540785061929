import { IconButton, IconTypes, Menu } from '@flash-tecnologia/hros-web-ui-v2'
import IconsAndLabel from '../IconsAndLabel'

type ActionsOptionsType = {
  label: string
  icon: IconTypes
  onClick?: () => void
}[]

type ActionsButtonType = {
  actionsOptions: ActionsOptionsType
}

const ActionsButton = ({ actionsOptions }: ActionsButtonType) => {
  return (
    <Menu
      type="select"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      options={handlerActionOptions(actionsOptions)}
    >
      <IconButton icon="IconDotsVertical" variant="line" size="small" />
    </Menu>
  )
}

const handlerActionOptions = (options: ActionsOptionsType) => {
  return options.map(({ label, icon, onClick }) => ({
    children: <IconsAndLabel icon={icon} label={label} />,
    label: label,
    onClick: onClick,
  }))
}

export default ActionsButton
