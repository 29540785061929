import { Button, Icons, IconsProps } from '@flash-tecnologia/hros-web-ui-v2'
import {
  ContainerButtonStyled,
  ContainerStyled,
  LinkButtonStyled,
} from './styled'

type FooterType = {
  loading: boolean
  submitText: string
  onClose: () => void
  onSubmit: () => void
  iconLabel?: IconsProps['name']
  hasCancelButton?: boolean
  confirmation?: boolean
}

const Footer = ({
  confirmation,
  hasCancelButton = true,
  iconLabel,
  loading,
  onClose,
  onSubmit,
  submitText,
}: FooterType) => {
  const ButtonSubmit = () => (
    <Button
      variant="primary"
      variantType={confirmation ? 'error' : 'default'}
      buttonType="primary"
      size={'large'}
      onClick={onSubmit}
      loading={loading}
    >
      {submitText} {iconLabel && <Icons name={iconLabel} />}
    </Button>
  )

  return (
    <>
      {hasCancelButton
        ? (
          <ContainerStyled>
            <LinkButtonStyled variant="secondary" onClick={onClose}>
            Cancelar
            </LinkButtonStyled>
            <ButtonSubmit />
          </ContainerStyled>
        )
        : (
          <ContainerButtonStyled>
            <ButtonSubmit />
          </ContainerButtonStyled>
        )}
    </>
  )
}

export default Footer
