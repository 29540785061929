import { LinearProgress } from '@mui/material'
import styled from 'styled-components'

export const BoxStyled = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`

export const LinearProgressStyled = styled(LinearProgress)<{
  height?: number
  width?: number
}>`
  .MuiLinearProgress-bar {
    border-radius: ${({ theme }) => theme.borders.radius.xs};
    background-color: ${({ theme, value }) =>
    value === 100
      ? theme.colors.feedback.success[40]
      : theme.colors.secondary[50]};
  }
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: ${({ theme }) => theme.borders.radius.xs};
  height: ${({ theme, height }) => height ?? theme.spacings.xs4};
  width: ${({ theme, width }) => width ?? theme.spacings.xl4};
`
