import { trpc } from '@api/client'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'

type BenefitType = {
  description: string
  emoji: string
  id: string
  name: string
}

export const usePickBenefits = handleChangeInput => {
  const [selectedBenefits, setSelectedBenefits] = useState<BenefitType[]>([])
  const [benefits, setBenefits] = useState<BenefitType[]>([])

  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()
  const { data, isLoading: loading } = trpc.getAllExternalBenefit.useQuery(
    { companyId, searchParam: '' },
    {
      onSuccess: ({ benefits }) => {
        setBenefits(benefits)
      },
      queryKey: ['getAllExternalBenefit', { companyId, nullBenefitEnabled: true }],
      refetchOnWindowFocus: false,
    }
  )

  const handleCheckAll = (checked: boolean) => {
    const newSelectedBenefits = checked ? benefits : []
    setSelectedBenefits(newSelectedBenefits)
    handleChangeInput({
      target: { id: 'benefits', value: newSelectedBenefits },
    })
  }

  const handleCheckBenefit = checkedBenefitId => {
    const benefitPreviouslySelected = !!selectedBenefits.find(
      ({ id }) => id === checkedBenefitId
    )
    const oldBenefit = benefits.find(({ id }) => id === checkedBenefitId)

    const newSelectedBenefits = benefitPreviouslySelected
      ? selectedBenefits.filter(({ id }) => id !== checkedBenefitId)
      : oldBenefit
        ? [...selectedBenefits, oldBenefit]
        : selectedBenefits

    setSelectedBenefits(newSelectedBenefits)
    handleChangeInput({
      target: { id: 'benefits', value: newSelectedBenefits },
    })
  }

  const handleSearchBenefits = (searchParam = '') => {
    setBenefits(
      data?.benefits.filter(({ name }) =>
        name.match(new RegExp(searchParam, 'i'))
      ) ?? []
    )
  }

  return {
    allSelected: selectedBenefits.length === benefits.length,
    benefits,
    handleCheckAll,
    handleCheckBenefit,
    handleSearchBenefits,
    loading,
    selectedBenefits,
  }
}
