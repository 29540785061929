import {
  Button,
  Focused,
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import {
  ContentGridContainerStyled,
  LeftPanelContainerStyled,
  RightPanelContainerStyled,
} from './styled'
import { useTheme } from 'styled-components'

import LeftContent from '@components/LeftContent'

import { useAssignEmployeesToChoice } from './useAssignEmployeesToChoice'
import EmployeesList from './EmployeesList'
import ModalActions from '@components/ModalActions'
import ScheduleAndCommunication from './ScheduleAndCommunication'
import Space from '@components/Space'

const AssignEmployeesToChoice = () => {
  const theme = useTheme()
  const {
    activeStep,
    confirmModalOpen,
    flowCurrentStep,
    handleCancel,
    handleCheckSendLater,
    handleCheckSendNow,
    handleConfirm,
    handleNextStep,
    handlePreviousStep,
    handleSave,
    navigate,
    selected,
    setConfirmModalOpen,
    setSelectedEmployees,
    isNoEmployeesSelectedModalVisible,
    confirmNoEmployeesSelected,
    setIsNoEmployeesSelectedModalVisible,
  } = useAssignEmployeesToChoice()

  const steps = [
    {
      description:
        'Selecione as pessoas que poderão escolher entre os benefícios da escolha',
      rightPanel: <EmployeesList setSelectedEmployees={setSelectedEmployees} />,
      title: 'Adicionar pessoas',
    },
    {
      description:
        'Defina quando seus colaboradores receberão o e-mail comunicando que deverão \
       fazer sua escolha, bem como a data limite para que façam suas opções.',
      rightPanel: (
        <ScheduleAndCommunication
          selected={selected}
          handleCheckSendNow={handleCheckSendNow}
          handleCheckSendLater={handleCheckSendLater}
        />
      ),
      title: 'Comunicação e prazo',
    },
  ]

  return (
    <Focused
      navigate={navigate}
      stepper={{
        activeStep: flowCurrentStep,
        steps: [
          'Dados básicos',
          'Benefícios',
          'Opção padrão',
          'Pessoas',
          'Comunicação e prazo',
        ],
      }}
      footer={{
        endActions: [
          <Button
            size="large"
            variant="secondary"
            key="back-button"
            onClick={handlePreviousStep}
          >
            <Icons name="IconArrowLeft" /> Voltar
          </Button>,
          activeStep < 1
            ? (
              <Button
                disabled={false}
                size="large"
                variant="primary"
                key="continue-button"
                onClick={handleNextStep}
              >
              Continuar <Icons name="IconArrowRight" />
              </Button>
            )
            : (
              <Button
                disabled={false}
                size="large"
                variant="primary"
                key="continue-button"
                onClick={handleSave}
              >
              Salvar <Icons name="IconCheck" />
              </Button>
            ),
        ],
        startActions: [
          <LinkButton variant="secondary" onClick={handleCancel}>
            Cancelar
          </LinkButton>,
        ],
      }}
      routes={[
        {
          label: 'Atribuição de benefícios',
          route: '/simple-assignment',
        },
        {
          label: 'Escolhas',
          route: '/simple-assignment/benefit-choices',
        },
        {
          label: 'Nova escolha',
        },
      ]}
    >
      <>
        <Space y="s" />
        <Typography variant="headline6" variantColor={theme.colors.neutral[10]}>
          Nova escolha
        </Typography>
        <Space y="s" />
        <ContentGridContainerStyled>
          <LeftPanelContainerStyled>
            <LeftContent
              title={steps[activeStep].title}
              description={steps[activeStep].description}
            />
          </LeftPanelContainerStyled>
          <RightPanelContainerStyled>
            {steps[activeStep].rightPanel}
          </RightPanelContainerStyled>
        </ContentGridContainerStyled>
      </>
      <ModalActions
        subtitle="Atenção"
        type="success"
        iconHeader="IconAlertCircle"
        isOpen={confirmModalOpen}
        loading={false}
        title="Tem certeza que deseja enviar a comunicação agora?"
        description="Confirmando o envio, todos os colaboradores selecionados
          receberão o e-mail avisando para que cada um faça sua escolha."
        submitText="Confirmar envio"
        onClose={() => setConfirmModalOpen(false)}
        onSubmit={handleConfirm}
      />
      <ModalActions
        subtitle="Atenção!"
        type="success"
        iconHeader="IconAlertCircle"
        isOpen={isNoEmployeesSelectedModalVisible}
        loading={false}
        title="Tem certeza que deseja continuar sem selecionar pessoas?"
        description={
          <>
            <Typography
              variant="body3"
              variantColor={theme.colors.neutral[50]}
              weight={400}
            >
              Nenhuma pessoa foi selecionada. Tem certeza que deseja continuar
              sem selecionar ninguém?
              <br />
              Se sim, você poderá selecionar pessoas mais tarde
            </Typography>
          </>
        }
        submitText="Sim, continuar sem selecionar"
        onClose={() => setIsNoEmployeesSelectedModalVisible(false)}
        onSubmit={confirmNoEmployeesSelected}
      />
    </Focused>
  )
}

export default AssignEmployeesToChoice
