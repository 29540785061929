import {
  Button,
  Icons,
  PageContainer,
  Table,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useBenefitChoices } from './useBenefitChoices'
import EmptyState from '@components/EmptyState'
import Space from '@components/Space'
import getColumnsBenefitChoices from './getColumnsBenefitChoices'

const BenefitChoices = ({ navigate }) => {
  const {
    choices,
    effectiveNavigate,
    pagination,
    totalChoices,
    searchTerm,
    setPagination,
    setSearch,
    setParams,
    translation,
  } = useBenefitChoices({ navigate })

  const columns = getColumnsBenefitChoices({ navigate: effectiveNavigate })

  if (!choices?.length && !searchTerm?.length) {
    return (
      <EmptyState
        buttonAriaLabel={translation('notFound.buttonAriaLabel')}
        buttonLabel={translation('notFound.buttonLabel')}
        description={translation('notFound.description')}
        handleButtonClick={() =>
          effectiveNavigate('/benefit-choices/create-new-choice')
        }
        title={translation('notFound.title')}
      />
    )
  }

  return (
    <PageContainer>
      <Table.Root>
        <Table.Search
          clearIcon={<Icons name="IconX" onClick={() => setSearch('')} />}
          label={translation('searchChoice')}
          onChange={({ target }) => {
            setSearch(target.value)
          }}
        />
        <Table.Grid
          columns={columns}
          data={choices}
          fetchData={setParams}
          loading={false}
          rowIdKey={'_id'}
          pageSize={pagination.pageSize}
          options={{}}
          emptyState={{
            children: (
              <Button
                size="large"
                variant="primary"
                onClick={() =>
                  effectiveNavigate('/benefit-choices/create-new-choice')
                }
                aria-label={translation('noChoices')}
              >
                {translation('createChoices')}
                <Icons name="IconUserPlus" />
              </Button>
            ),
            isFiltered: true,
            message: translation('noChoices'),
          }}
        />
        <Table.Pagination
          count={totalChoices}
          onPaginationChange={value => setPagination(value)}
          pagination={pagination}
          pageSizeOptions={[{ label: '10 itens', value: 10 }, { label: '20 itens', value: 20 }]}
        />
      </Table.Root>
      <Space y="xs" />
    </PageContainer>
  )
}

export default BenefitChoices
