import { BasicInformationRightPanel } from './BasicInformation'
import {
  Button,
  Focused,
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import {
  ContentGridContainerStyled,
  LeftPanelContainerStyled,
  RightPanelContainerStyled,
} from './styled'
import { DefaultOptionRightPanel } from './DefaultOption'
import { PickBenefitsRightPanel } from './PickBenefits'
import { useCreateNewChoice } from './useCreateNewChoice'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import LeftContent from '@components/LeftContent'
import ModalActions from '@components/ModalActions'
import Space from '@components/Space'

const CreateNewChoice = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const {
    activeStep,
    cancelModalOpen,
    defaultBenefit,
    handleCancel,
    handleChangeInput,
    handleNextStep,
    handlePreviousStep,
    selectedBenefits,
    setCancelModalOpen,
  } = useCreateNewChoice()

  const steps = [
    {
      description: 'Defina as informações básicas da escolha a ser criada',
      rightPanel: (
        <BasicInformationRightPanel handleChangeInput={handleChangeInput} />
      ),
      title: 'Dados básicos',
    },
    {
      description:
        'Escolha os benefícios que serão opções da nova escolha.\
        Indique também se a escolha será opcional ou não.',
      rightPanel: (
        <PickBenefitsRightPanel handleChangeInput={handleChangeInput} />
      ),
      title: 'Escolher benefícios',
    },
    {
      description:
        'Defina se a escolha terá um dos benefícios como opção padrão\
        caso a pessoa não faça sua escolha.',
      rightPanel: (
        <DefaultOptionRightPanel
          defaultBenefit={defaultBenefit}
          handleChangeInput={handleChangeInput}
          selectedBenefits={selectedBenefits}
        />
      ),
      title: 'Opção padrão',
    },
  ]

  return (
    <Focused
      navigate={navigate}
      stepper={{
        activeStep,
        steps: [
          'Dados básicos',
          'Benefícios',
          'Opção padrão',
          'Pessoas',
          'Comunicação e prazo',
        ],
      }}
      footer={{
        endActions: [
          activeStep > 0 && (
            <Button
              size="large"
              variant="secondary"
              key="back-button"
              onClick={handlePreviousStep}
            >
              <Icons name="IconArrowLeft" /> Voltar
            </Button>
          ),
          <Button
            disabled={false}
            size="large"
            variant="primary"
            key="continue-button"
            onClick={handleNextStep}
          >
            Continuar <Icons name="IconArrowRight" />
          </Button>,
        ],
        startActions: [
          <LinkButton variant="secondary" onClick={handleCancel}>
            Cancelar
          </LinkButton>,
        ],
      }}
      routes={[
        {
          label: 'Atribuição de benefícios',
          route: '/simple-assignment',
        },
        {
          label: 'Escolhas',
          route: '/simple-assignment/benefit-choices',
        },
        {
          label: 'Nova escolha',
        },
      ]}
    >
      <>
        <Space y="s" />
        <Typography variant="headline6" variantColor={theme.colors.neutral[10]}>
          Nova escolha
        </Typography>
        <Space y="s" />
        <ContentGridContainerStyled>
          <LeftPanelContainerStyled>
            <LeftContent
              title={steps[activeStep].title}
              description={steps[activeStep].description}
            />
          </LeftPanelContainerStyled>
          <RightPanelContainerStyled>
            {steps[activeStep].rightPanel}
          </RightPanelContainerStyled>
        </ContentGridContainerStyled>
      </>
      <ModalActions
        subtitle="Atenção"
        type="confirmation"
        iconHeader="IconAlertCircle"
        isOpen={cancelModalOpen}
        loading={false}
        title="Cancelar criação de escolha"
        description="Tem certeza que quer cancelar a criação de escolha?"
        submitText="Confirmar"
        onClose={() => setCancelModalOpen(false)}
        onSubmit={() => navigate('/simple-assignment/benefit-choices')}
      />
    </Focused>
  )
}

export default CreateNewChoice
