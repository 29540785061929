import { TransferList } from '@flash-tecnologia/hros-web-ui-v2'

import { useExternalBenefitAssignToEmployees } from './useExternalBenefitAssignToEmployees'

import { ContainerStyled } from './styled'
import EmployeeItem from '@components/EmployeeItem'

const ExternalBenefitAssignToEmployees = ({
  externalBenefitId,
  setEmployeesToAssign,
}) => {
  const {
    handleLeftCheck,
    handleLeftSearchEmployees,
    handleRightCheck,
    handleRightSearchEmployees,
    handleTransferEmployee,
    isLoadingEmployees,
    leftListData,
    rightListData,
  } = useExternalBenefitAssignToEmployees({
    externalBenefitId,
    setEmployeesToAssign,
  })

  const columns = [
    {
      Cell: ({ row }) => (
        <EmployeeItem
          hasAttribution={row?.original?.hasAttribution}
          name={row?.original?.name}
          cpf={row?.original?.cpf}
        />
      ),
      Header: 'Nome',
      accessor: 'name',
      disableSortBy: true,
    },
  ]

  return (
    <ContainerStyled>
      <TransferList
        columns={columns}
        leftList={{
          data: leftListData.sort((a, b) => a.name.localeCompare(b.name)),
          loading: isLoadingEmployees,
          onCheck: handleLeftCheck,
          onSearch: handleLeftSearchEmployees,
          title: `Todas pessoas (${leftListData.length})`,
          total: leftListData.length,
        }}
        rightList={{
          data: rightListData.sort((a, b) => a.name.localeCompare(b.name)),
          loading: false,
          onCheck: handleRightCheck,
          onSearch: handleRightSearchEmployees,
          title: `Selecionados para a atribuição (${rightListData.length})`,
          total: rightListData.length,
        }}
        onTransfer={handleTransferEmployee}
      />
    </ContainerStyled>
  )
}

export default ExternalBenefitAssignToEmployees
