import { EVENTS } from '@utils/consts/tracking'
import { trpc } from '@api/client'
import { useEventTracking } from '@utils/hooks/useEventTracking'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'

export const useChooseExternalBenefit = ({
  externalBenefitId,
  setExternalBenefitId,
}) => {
  const [searchParam, setSearchParam] = useState<string>('')
  const { trackEvent } = useEventTracking()
  const [selected, setSelected] = useState<string | null>(externalBenefitId)

  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()
  const { data, isLoading, refetch } = trpc.getAllExternalBenefit.useQuery(
    { companyId, searchParam },
    {
      queryKey: ['getAllExternalBenefit', { companyId, searchParam }],
      staleTime: Infinity,
    }
  )

  const fetchData = ({ searchParam = '' }) => {
    refetch({ queryKey: ['getAllExternalBenefit', { searchParam }] })
    setSearchParam(searchParam ?? '')
  }

  const handleSearchOnFocus = () => {
    trackEvent({
      name: EVENTS.SEARCH_EXTERNAL_BENEFITS,
    })
  }

  const handleSelectBenefit = externalBenefitId => {
    setExternalBenefitId(externalBenefitId)
    setSelected(externalBenefitId)
  }

  return {
    benefits: data?.benefits,
    fetchData,
    handleSearchOnFocus,
    handleSelectBenefit,
    isLoading,
    selected,
  }
}
