import {
  DatePicker,
  NotificationCard,
  Radio,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'

import {
  CommunicationContainerStyled,
  ContainerStyled,
  DateContainerStyled,
  RadioContainerStyled,
} from './styled'

import { useTheme } from 'styled-components'
import Space from '@components/Space'

type ScheduleAndCommunicationType = {
  selected: string
  handleCheckSendNow: () => void
  handleCheckSendLater: () => void
}

const ScheduleAndCommunication = ({
  selected,
  handleCheckSendNow,
  handleCheckSendLater,
}: ScheduleAndCommunicationType) => {
  const theme = useTheme()

  return (
    <div>
      <ContainerStyled>
        <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
          Envio de comunicação
        </Typography>
        <Space y="xs5" />
        <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
          Deseja enviar o e-mail para as pessoas selecionadas neste momento?
        </Typography>
        <Space y="xs" />
        <CommunicationContainerStyled>
          <RadioContainerStyled>
            <Radio
              key="sendNow"
              value="sendNow"
              checked={selected === 'sendNow'}
              onChange={() => handleCheckSendNow()}
            />
            <Typography
              variant="body3"
              weight={600}
              variantColor={theme.colors.neutral[30]}
            >
              Enviar agora
            </Typography>
          </RadioContainerStyled>
          <RadioContainerStyled>
            <Radio
              key="sendLater"
              value="sendLater"
              checked={selected === 'sendLater'}
              onChange={() => handleCheckSendLater()}
            />
            <Typography
              variant="body3"
              weight={600}
              variantColor={theme.colors.neutral[30]}
            >
              Enviar em outro momento
            </Typography>
          </RadioContainerStyled>
        </CommunicationContainerStyled>

        {selected === 'sendLater' && (
          <>
            <Space y="xs" />
            <NotificationCard.Root
              type="error"
              variant="outlined"
              children={
                <>
                  <NotificationCard.Icon iconName="IconAlertCircle" />
                  <NotificationCard.WrapperTexts>
                    <NotificationCard.Title>Atenção</NotificationCard.Title>

                    <NotificationCard.Subtitle>
                      Enquanto seus colaboradores não receberem o e-mail de
                      comunicação, eles não terão como saber que já podem
                      exercer sua opção de escolha. Você poderá enviar o e-mail
                      clicando no botão de opções no menu de Escolhas.
                    </NotificationCard.Subtitle>
                  </NotificationCard.WrapperTexts>
                </>
              }
            />
          </>
        )}
      </ContainerStyled>
      <Space y="s" />
      <ContainerStyled>
        <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
          Data limite para o colaborador fazer a escolha
        </Typography>
        <Space y="xs5" />
        <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
          Deixe o campo em branco caso não queira definir um prazo limite
        </Typography>
        <Space y="xs" />
        <DateContainerStyled>
          <DatePicker
            placeholder="Escolha uma data"
            label="Escolha uma data"
            onDateChange={() => ({})}
          />
        </DateContainerStyled>
      </ContainerStyled>
    </div>
  )
}
export default ScheduleAndCommunication
