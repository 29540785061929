import { ToastProps } from '@flash-tecnologia/hros-web-ui-v2'
import { useCallback } from 'react'

export function useDispatchToast() {
  const dispatchToast = useCallback((detail: ToastProps) => {
    return dispatchEvent(new CustomEvent('showToast', { detail }))
  }, [])

  return {
    dispatchToast,
  }
}
