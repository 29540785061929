import {
  BenefitDescriptionContainerStyled,
  BenefitDescriptionStyled,
  BenefitEmojiContainerStyled,
  BenefitItemContainerStyled,
} from './styled'
import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

type ExternalBenefitItemType = {
  description?: string
  emoji?: string
  name?: string
}

const ExternalBenefitItem = ({
  name,
  emoji,
  description,
}: ExternalBenefitItemType) => {
  const theme = useTheme()

  return (
    <BenefitItemContainerStyled>
      <BenefitEmojiContainerStyled>
        <img src={emoji} alt={name} aria-hidden="true" />
      </BenefitEmojiContainerStyled>

      <BenefitDescriptionContainerStyled>
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[40]}
          weight={600}
        >
          {name}
        </Typography>
        <BenefitDescriptionStyled variant="body4">
          {description}
        </BenefitDescriptionStyled>
      </BenefitDescriptionContainerStyled>
    </BenefitItemContainerStyled>
  )
}

export default ExternalBenefitItem
