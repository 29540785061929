import { ErrorBoundary } from '@utils/ErrorBoundary'
import BenefitChoicesTab from './components/BenefitChoicesTab'
import ExternalBenefitSimpleAssignTab from './components/ExternalBenefitSimpleAssignTab'
import ExternalBenefitTab from './components/ExternalBenefitTab'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './root.component'
import singleSpaReact from 'single-spa-react'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  errorBoundaryClass: ErrorBoundary,
  rootComponent: Root,
})

const { bootstrap, mount, unmount } = lifecycles
export {
  bootstrap,
  mount,
  unmount,
  ExternalBenefitTab,
  ExternalBenefitSimpleAssignTab,
  BenefitChoicesTab,
}
