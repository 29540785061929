import { Checkbox, Table, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { usePickBenefits } from './usePickBenefits'
import { useTheme } from 'styled-components'
import ExternalBenefitItem from '@components/ExternalBenefitItem'

export const PickBenefitsRightPanel = ({ handleChangeInput }) => {
  const {
    allSelected,
    benefits,
    handleCheckAll,
    handleCheckBenefit,
    handleSearchBenefits,
    loading,
    selectedBenefits,
  } = usePickBenefits(handleChangeInput)
  const theme = useTheme()

  return (
    <>
      <Typography variantColor={theme.colors.neutral[30]} variant="headline8">
        Benefícios
      </Typography>
      <Table.Root>
        <Table.Search
          label="Buscar por benefício"
          onChange={event => handleSearchBenefits(event?.target?.value ?? '')}
        />
        <Table.Grid
          loading={loading}
          rowIdKey="id"
          options={{}}
          pageSize={1000}
          hasPagination={false}
          data={benefits}
          columns={[
            {
              Cell: ({
                row,
              }: {
                row?: {
                  original: { id: string }
                }
              }) => (
                <Checkbox
                  key={row?.original.id}
                  value={row?.original.id}
                  onChange={() => handleCheckBenefit(row?.original.id)}
                  checked={
                    !!selectedBenefits.find(({ id }) => id === row?.original.id)
                  }
                />
              ),
              Header: (
                <Checkbox
                  onChange={(_, checked) => handleCheckAll(checked)}
                  checked={allSelected}
                />
              ),
              accessor: 'id',
              disableSortBy: true,
            },
            {
              Cell: ({
                row,
              }: {
                row?: {
                  values: { name: string }
                  original: {
                    emoji: string
                    description: string
                    name: string
                  }
                }
              }) => (
                <ExternalBenefitItem
                  name={row?.original.name}
                  description={row?.original?.description}
                  emoji={row?.original?.emoji}
                />
              ),
              Header: 'Benefício',
              accessor: 'name',
              disableSortBy: true,
            },
          ]}
        />
      </Table.Root>
    </>
  )
}
