import ModalActions from '@components/ModalActions'

type DeletedModalType = {
  hasEmployeeSelected: boolean
  isOpen: boolean
  loading: boolean
  onClose: () => void
  onSubmit: () => void
}

const DeletedModal = ({
  hasEmployeeSelected,
  isOpen,
  loading,
  onClose,
  onSubmit,
}: DeletedModalType) => {
  return (
    <ModalActions
      subtitle="Atenção"
      type="confirmation"
      iconButton="IconTrash"
      iconHeader="IconAlertCircle"
      isOpen={isOpen}
      loading={loading}
      title={
        hasEmployeeSelected
          ? 'Tem certeza que deseja excluir as pessoas deste benefício?'
          : 'Tem certeza que deseja excluir a pessoa deste benefício?'
      }
      description={
        hasEmployeeSelected
          ? 'Se confirmar a exclusão, as pessoas não estarão mais atribuídas ao benefício.'
          : 'Se confirmar a exclusão, a pessoa não estará mais atribuída ao benefício.'
      }
      submitText="Confirmar exclusão"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}

export default DeletedModal
