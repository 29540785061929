import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'
import Space from '@components/Space'

type LeftContentType = {
  title: string
  description: string
}

const LeftContent = ({ title, description }: LeftContentType) => {
  const theme = useTheme()

  return (
    <>
      <Typography variant="headline7" variantColor={theme.colors.secondary[50]}>
        {title}
      </Typography>
      <Space y="xs" />
      <Typography variant="body3" variantColor={theme.colors.neutral[50]}>
        {description}
      </Typography>
    </>
  )
}

export default LeftContent
