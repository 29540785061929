import { dispatchToast } from '../../utils'
import { emojiDefault } from '../../components/EmojiPicker/constants'
import { trpc } from '../../api/client'
import { useNavigate } from 'react-router-dom'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useCreateExternalBenefit = () => {
  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()
  const navigate = useNavigate()
  const [externalBenefit, setExternalBenefit] = useState({
    description: '',
    emoji: emojiDefault,
    name: '',
  })

  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [errorEmptyName, setErrorEmptyName] = useState(false)

  const [translation] = useTranslation('translations', {
    keyPrefix: 'createExternalBenefit',
  })

  const createExternalBenefitMutation = trpc.createExternalBenefit.useMutation({
    onError: error => {
      setIsLoadingConfirm(false)
      dispatchToast({
        content: error.message,
        type: 'error',
      })
    },
    onSuccess: () => {
      setIsLoadingConfirm(false)
      dispatchToast({
        content: translation('successfullyCreatedExternalBenefit'),
        type: 'success',
      })

      navigate('/benefit-management/external-benefit', { replace: true })
    },
  })

  const onChangeInput = ({
    target,
  }: {
    target: { name: string; id: string; value?: string }
  }) => {
    setExternalBenefit({
      ...externalBenefit,
      [target.name || target?.id]: target.value,
    })

    if (target.value && target.name === 'name') setErrorEmptyName(false)
  }

  const handleConfirm = () => {
    if (!externalBenefit.name) {
      if (!externalBenefit.name) setErrorEmptyName(true)
      return
    }

    setIsLoadingConfirm(true)
    createExternalBenefitMutation.mutate({
      companyId,
      description: externalBenefit.description?.trim(),
      emoji: externalBenefit.emoji.trim(),
      name: externalBenefit.name.trim(),
    })
  }

  const handleCancel = () => {
    navigate('/benefit-management/external-benefit', { replace: true })
  }

  return {
    errorEmptyName,
    externalBenefit,
    handleCancel,
    handleConfirm,
    isLoadingConfirm,
    navigate,
    onChangeInput,
    translation,
  }
}
