import { IconsProps, Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2'

import {
  ContainerStyled,
  IconConfirmationStyled,
  IconSuccessStyled,
  StyledIconButton,
  TextWrapperStyled,
} from './styled'
import { ReactNode } from 'react'
import { useTheme } from 'styled-components'
import Footer from './Footer'

type ModalActionsType = {
  iconButton?: IconsProps['name']
  isOpen: boolean
  loading: boolean
  title: string
  description: ReactNode
  submitText: string
  onClose: () => void
  onSubmit: () => void
  type?: 'confirmation' | 'success'
  iconHeader?: IconsProps['name']
  subtitle?: string
  hasCancelButton?: boolean
}

const ModalActions = ({
  iconButton,
  isOpen,
  loading,
  title,
  description,
  submitText,
  onClose,
  onSubmit,
  type,
  iconHeader,
  subtitle,
  hasCancelButton = true,
}: ModalActionsType) => {
  const theme = useTheme()
  const confirmation = type === 'confirmation'

  return (
    <Modal.Root
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer
          hasCancelButton={hasCancelButton}
          confirmation={confirmation}
          onClose={onClose}
          onSubmit={onSubmit}
          submitText={submitText}
          loading={loading}
          iconLabel={iconButton}
        />
      }
    >
      <ContainerStyled>
        <TextWrapperStyled>
          {iconHeader && (
            <>
              {confirmation
                ? (
                  <IconConfirmationStyled
                    name={iconHeader}
                    size={64}
                    color={theme.colors.feedback.error[40]}
                  />
                )
                : (
                  <IconSuccessStyled
                    name={iconHeader}
                    size={64}
                    color={theme.colors.secondary[50]}
                  />
                )}
            </>
          )}
          <Typography
            variant="body3"
            variantColor={
              confirmation
                ? theme.colors.feedback.error[40]
                : theme.colors.secondary[50]
            }
            weight={700}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[20]}
            weight={700}
          >
            {title}
          </Typography>
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[10]}
            weight={400}
          >
            {description}
          </Typography>
        </TextWrapperStyled>
        <StyledIconButton
          icon="IconX"
          onClick={onClose}
          variant="line"
          size="small"
        />
      </ContainerStyled>
    </Modal.Root>
  )
}

export default ModalActions
