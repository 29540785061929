import { ERROR_MESSAGES } from '@utils/consts/errorMessages'
import { dispatchToast } from '../../utils'
import { emojiDefault } from '@components/EmojiPicker/constants'
import { trpc } from '../../api/client'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type ExternalBenefitState = {
  description?: string
  emoji: string
  name: string
  isEditable?: boolean
}

export const useEditExternalBenefit = () => {
  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()
  const navigate = useNavigate()
  const [translation] = useTranslation('translations', {
    keyPrefix: 'createExternalBenefit',
  })

  const location = useLocation()
  const state = location.state as { externalBenefitId: string | undefined }
  const externalBenefitId = state?.externalBenefitId
  const [isLoading, setIsLoading] = useState(!!externalBenefitId)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [disableSaveEditButton, setDisableSaveEditButton] = useState(false)
  const [errorEmptyName, setErrorEmptyName] = useState(false)
  const [externalBenefit, setExternalBenefit] = useState<ExternalBenefitState>({
    description: '',
    emoji: emojiDefault,
    name: '',
  })

  if (!externalBenefitId) {
    dispatchToast({
      content: translation('failedToGetExternalBenefit'),
      type: 'error',
    })
    navigate('/benefit-management/external-benefit', { replace: true })
  } else {
    trpc.getExternalBenefit.useQuery(
      { companyId, id: externalBenefitId },
      {
        onError: error => {
          setIsLoading(false)
          dispatchToast({
            content: error.message,
            type: 'error',
          })
        },
        onSuccess: ({ description, name, emoji, isEditable }) => {
          if (!isEditable) {
            setDisableSaveEditButton(true)
            dispatchToast({
              content: ERROR_MESSAGES.BENEFIT_EDIT_FORBIDDEN,
              type: 'error',
            })
          }
          setExternalBenefit({ description, emoji, name })
          setIsLoading(false)
        },
        refetchOnWindowFocus: false,
        retry: false,
      }
    )
  }

  const updateExternalBenefitMutation = trpc.updateExternalBenefit.useMutation({
    onError: error => {
      setIsLoadingConfirm(false)
      dispatchToast({
        content: error.message,
        type: 'error',
      })
    },
    onSuccess: () => {
      setIsLoadingConfirm(false)
      dispatchToast({
        content: translation('successfullyEditedExternalBenefit'),
        type: 'success',
      })
      navigate('/benefit-management/external-benefit', { replace: true })
    },
  })

  const onChangeInput = ({
    target,
  }: {
    target: { name: string; id: string; value?: string }
  }) => {
    setExternalBenefit({
      ...externalBenefit,
      [target.name || target?.id]: target.value,
    })

    if (target.value && target.name === 'name') setErrorEmptyName(false)
  }

  const handleConfirm = () => {
    if (!externalBenefit.name) {
      if (!externalBenefit.name) setErrorEmptyName(true)
      return
    }

    setIsLoadingConfirm(true)
    if (externalBenefitId) {
      updateExternalBenefitMutation.mutate({
        companyId: companyId as string,
        description: externalBenefit.description?.trim(),
        emoji: externalBenefit.emoji?.trim(),
        id: externalBenefitId,
        name: externalBenefit.name?.trim(),
      })
    }
  }

  const handleCancel = () => {
    setDisableSaveEditButton(false)
    navigate('/benefit-management/external-benefit', { replace: true })
  }

  return {
    disableSaveEditButton,
    errorEmptyName,
    externalBenefit,
    handleCancel,
    handleConfirm,
    isLoading,
    isLoadingConfirm,
    navigate,
    onChangeInput,
    translation,
  }
}
