import { Radio, Table, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'
import ExternalBenefitItem from '@components/ExternalBenefitItem'

export const DefaultOptionRightPanel = ({
  defaultBenefit,
  handleChangeInput,
  selectedBenefits,
}) => {
  const theme = useTheme()

  return (
    <>
      <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
        Opção padrão
      </Typography>
      <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
        Caso a pessoa não selecione um benefício, ou demore muito para decidir,
        você gostaria de definir um dos benefícios como opção padrão da escolha?
        Dessa forma ele poderá ser atribuído quando não houver definição.
      </Typography>
      <Table.Root>
        <Table.Grid
          loading={false}
          rowIdKey="id"
          options={{}}
          pageSize={1000}
          hasPagination={false}
          data={selectedBenefits}
          columns={[
            {
              Cell: ({
                row,
              }: {
                row?: {
                  original: { id: string }
                }
              }) => (
                <Radio
                  key={row?.original.id}
                  value={row?.original.id}
                  checked={defaultBenefit === row?.original.id}
                  onChange={({ target }) =>
                    handleChangeInput({
                      target: {
                        id: 'defaultBenefit',
                        value: target.value,
                      },
                    })
                  }
                />
              ),
              accessor: 'id',
              disableSortBy: true,
            },
            {
              Cell: ({
                row,
              }: {
                row?: {
                  values: { name: string }
                  original: {
                    emoji: string
                    description: string
                    name: string
                  }
                }
              }) => {
                return (
                  <ExternalBenefitItem
                    name={row?.original.name}
                    description={row?.original?.description}
                    emoji={row?.original?.emoji}
                  />
                )
              },
              Header: 'Benefício',
              accessor: 'name',
              disableSortBy: true,
            },
          ]}
        />
      </Table.Root>
    </>
  )
}
