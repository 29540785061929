import { DefaultTheme } from 'styled-components'
import {
  NotificationCard,
  Skeleton,
  TextArea,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'

type Props = {
  choice: {
    id: string
    name: string
    description: string
  }
  handleChangeInput: ({ target }) => void
  loading: boolean
  theme: DefaultTheme
}

export const BasicInformationRightPanel = ({
  choice,
  handleChangeInput,
  loading,
  theme,
}: Props) => {
  return (
    <>
      <NotificationCard.Root
        type="error"
        variant="outlined"
        children={
          <>
            <NotificationCard.Icon iconName="IconAlertCircle" />
            <NotificationCard.WrapperTexts>
              <NotificationCard.Title>Atenção</NotificationCard.Title>

              <NotificationCard.Subtitle>
                Para edição de prazos para escolha, a alteração deve ser feita
                diretamente pela tabela.
              </NotificationCard.Subtitle>
            </NotificationCard.WrapperTexts>
          </>
        }
      />

      <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
        Dados da escolha
      </Typography>

      {loading
        ? (
          <>
            <Skeleton variant="rounded" height={'50px'} width={'100%'} />
            <Skeleton variant="rounded" height={'200px'} width={'100%'} />
          </>
        )
        : (
          <>
            <TextField
              id="name"
              name="name"
              value={choice.name}
              label="Nome da escolha"
              inputProps={{ maxLength: 50 }}
              fullWidth
              onChange={({ target }) => handleChangeInput({ target })}
            />
            <TextArea
              id="description"
              value={choice.description}
              placeholder="Adicione uma descrição para seus colaboradores"
              maxLength={600}
              onChange={({ target }) => handleChangeInput({ target })}
            />
          </>
        )}
    </>
  )
}
