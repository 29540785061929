import { AutocompleteStyled } from './styled'
import { EmojiLabelStyled } from '@components/EmojiPicker/EmojiLabel'
import { TextField } from '@flash-tecnologia/hros-web-ui-v2'
import { emojiS3Urls } from '../EmojiPicker/constants'
import { useMemo } from 'react'

export interface EmojiPickerProps {
  value: string
  label: string
  name: string
  onChange: (event: { target: { name: string; value: string } }) => void
}

const EmojiPickerAutocomplete = (props: EmojiPickerProps) => {
  const { value, label, name, onChange } = props

  const emojiOptions = useMemo(
    () =>
      emojiS3Urls.map(emojiS3Url => ({
        label: <EmojiLabelStyled src={emojiS3Url} alt="" />,
        value: emojiS3Url,
      })),
    []
  )

  return (
    <AutocompleteStyled
      id={name}
      options={emojiOptions}
      clearIcon={<></>}
      getOptionLabel={() => ''}
      onChange={(_, newValue: { value: string }) => {
        if (newValue) {
          onChange({ target: { name, value: newValue.value } })
        }
      }}
      renderOption={(props, option: { label: string }) => {
        return (
          <li
            style={{
              display: 'inline-block',
            }}
            {...props}
          >
            {option.label}
          </li>
        )
      }}
      renderInput={params => {
        return (
          <TextField
            {...params}
            required
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>{value && <EmojiLabelStyled src={value} alt={label} />}</>
              ),
            }}
          />
        )
      }}
    />
  )
}

export default EmojiPickerAutocomplete
