import { IconButton, Icons } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const TextWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  max-width: 420px;
  text-align: center;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacings.m};
  margin-right: ${({ theme }) => theme.spacings.m};
`

export const IconConfirmationStyled = styled(Icons)`
  padding: ${({ theme }) => theme.spacings.xs4};
  background: ${({ theme }) => theme.colors.feedback.error[90]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
`

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacings.xs};
  right: ${({ theme }) => theme.spacings.xs};
`

export const IconSuccessStyled = styled(Icons)`
  padding: ${({ theme }) => theme.spacings.xs2};
  background: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
`
