import { trpc } from '@api/client'
import { useDispatchToast } from '@utils/hooks/useDispatchToast'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useTranslation } from 'react-i18next'

type Choice = {
  benefits: string[]
  companyId: string
  createdAt: string
  default: string
  deleted: boolean
  description: string
  id: string
  name: string
  totalAppliedChoices: number
  totalChoicesMade: number
  totalEmployees: number
  updatedAt: string
}

export const useBenefitChoices = ({ navigate }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const effectiveNavigate = navigate || useNavigate()
  const { dispatchToast } = useDispatchToast()

  const [translation] = useTranslation('translations', {
    keyPrefix: 'choices',
  })

  const {
    selectedCompany: { id },
  } = useSelectedCompany()
  const companyId = id as string
  const [choices, setChoices] = useState<Choice[]>([])
  const [totalChoices, setTotalChoices] = useState(0)
  const [filter] = useState({ companyId })
  const [pagination, setPagination] = useState({
    index: 1,
    pageNumber: 1,
    pageSize: 10,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [sort, setSort] = useState({ name: 1 })

  const getBenefitChoicesMutation = trpc.findChoices.useMutation({
    onError: () => {
      dispatchToast({
        description: 'Tente novamente mais tarde.',
        title: 'Erro ao carregar escolhas',
        type: 'error',
      })
    },
    onSuccess: (data: { choices: []; total: number }) => {
      setChoices(data.choices)
      setTotalChoices(data.total)
    },
  })

  useMemo(
    () => {
      getBenefitChoicesMutation.mutate({
        filter,
        pagination: {
          limit: pagination.pageSize,
          skip: pagination.pageSize * (pagination.pageNumber - 1),
        },
        searchTerm,
        sort,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filter,
      pagination,
      searchTerm,
      sort,
    ]
  )

  const setParams = input => {
    const newSort = input.sortBy?.reduce((acc, sortBy) => {
      return { ...acc, [sortBy.id]: sortBy?.desc ? -1 : 1 }
    }, {})
    setSort(newSort)
  }

  const setSearch = searchTerm => {
    setPagination({
      ...pagination,
      pageNumber: 1,
    })

    setSearchTerm(searchTerm)
  }

  return {
    choices,
    effectiveNavigate,
    pagination,
    searchTerm,
    setPagination,
    setParams,
    setSearch,
    setSearchTerm,
    totalChoices,
    translation,
  }
}
