import {
  MonitoringErrorBoundary,
  MonitoringManager,
} from '@flash-tecnologia/hros-web-utility'
import { MonitoringLevel } from '@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel'

interface Error {
  name: string
  message: string
  stack?: string
}

export const projectDsn =
  'https://815dc60021b794b4511ec15caa0b642b@o266934.ingest.sentry.io/4506825813327872'

export class ErrorBoundary extends MonitoringErrorBoundary {
  project: string = projectDsn

  constructor(props) {
    super(props)
  }
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  static captureException(
    error: Error,
    severity?: MonitoringLevel,
    extras?: Record<string, any>
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras)
  }
  componentDidCatch(error: Error) {
    MonitoringManager.captureException(this.project, error)
    window.location.replace('/external-benefit/error-page')
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}
