/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Icons,
  LinkButton,
  PageContainer,
  PageHeader,
  Tab,
  Table,
  Tag,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useExternalBenefitAssignments } from './useExternalBenefitAssignments'

import {
  ActionContainer,
  BenefitsContainerStyled,
  BoxStyled,
  ContainerStyled,
  IconButtonContainer,
  LoadingContainerStyled,
  Option,
  SearchFieldStyled,
  TabBenefitsContainer,
  TableHeaderContainerStyled,
} from './styled'
import { Skeleton } from '@mui/material'
import { Title } from '@components/Title'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import DeletedModal from './components/DeletedModal'
import EmployeeItem from '@components/EmployeeItem'
import EmptyState from '@components/EmptyState'
import SendingReportModal from './components/SendingReportModal'
import Space from '@components/Space'
import SubmitReportModal from './components/SubmitReportModal'
import TabLabel from './components/TabLabel'

const ExternalBenefitAssignments = ({ navigate = undefined }) => {
  const [translation] = useTranslation('translations', {
    keyPrefix: 'emptyExternalBenefitAssign',
  })
  const theme = useTheme()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const effectiveNavigate = navigate || useNavigate()

  const {
    adminEmail,
    allSelected,
    selectedBenefit,
    benefits,
    employees,
    fetchData,
    fetchEmployeesData,
    handleCloseDeleteModal,
    handleCloseReadyModal,
    handleCloseReportModal,
    handleCloseSearch,
    handleDeleteEmployeeAssign,
    handleExternalBenefitAssign,
    handleNotFoundButtonClick,
    handleOpenReportModal,
    handleSelect,
    handleSubmitDeleteModal,
    handleSubmitReportModal,
    handleTabChanged,
    hasEmployeeSelected,
    initialLoading,
    isEmployeesLoading,
    isGetAllExternalBenefitsLoading,
    isOpenDeleteModal,
    isOpenReadyModal,
    isOpenReportModal,
    isUnassignEmployeesLoading,
    pageCount,
    pageNumber,
    pageSize,
    searchEmployeesParam,
    selectedEmployees,
    selectHeader,
    submitReportLoading,
    totalItems,
    searchParam,
  } = useExternalBenefitAssignments({ navigate: effectiveNavigate })

  const tabBenefits = useMemo(() => {
    if (!benefits) {
      return null
    }

    const tabItens = benefits.map(({ name, id }) => {
      const options = [
        {
          children: (
            <Option onClick={() => handleExternalBenefitAssign(id)}>
              <Icons name="IconUserPlus" />
              Atribuir pessoas
            </Option>
          ),
          key: 'assignEmployee',
        },
        {
          children: (
            <Option onClick={() => handleOpenReportModal(id)}>
              <Icons name="IconDownload" />
              Exportar atribuição
            </Option>
          ),
          key: 'downloadAssigns',
        },
      ]

      return {
        component: '',
        label: <TabLabel options={options} name={name} />,
      }
    })

    return (
      <TabBenefitsContainer>
        <Tab
          tabItens={tabItens}
          variant="secondary"
          onTabChanged={index => handleTabChanged(index)}
        />
      </TabBenefitsContainer>
    )
  }, [benefits])

  const TableHeader = () => {
    return (
      <TableHeaderContainerStyled>
        <Tag disableRipple={true} disableTouchRipple={true} variant="primary">
          {allSelected ? totalItems : selectedEmployees.size} de {totalItems}{' '}
          selecionados
        </Tag>
        <LinkButton
          variant="secondary"
          onClick={() => handleDeleteEmployeeAssign()}
        >
          <Icons name="IconTrash" />
          Excluir deste benefício
        </LinkButton>
      </TableHeaderContainerStyled>
    )
  }

  const columns = [
    {
      Cell: ({ row }) => (
        <Checkbox
          checked={selectedEmployees.has(row.original._id) || allSelected}
          onChange={() => handleSelect(row.original._id)}
        />
      ),
      Header: (
        <Checkbox
          checked={allSelected || selectHeader}
          indeterminate={allSelected || selectHeader}
          onChange={() => handleSelect()}
        />
      ),
      accessor: 'id',
      disableSortBy: true,
    },
    {
      Cell: ({ row }) => (
        <EmployeeItem name={row?.original?.name} cpf={row?.original?.cpf} />
      ),
      Header: hasEmployeeSelected ? <TableHeader /> : 'Nome',
      accessor: 'name',
      customSortKey: 'name',
      disableSortBy: true,
    },
    {
      Header: hasEmployeeSelected ? '' : 'Data de atribuição',
      accessor: 'attributionDate',
      customSortKey: 'attributionDate',
      disableSortBy: true,
    },
    {
      Cell: ({ row }) => (
        <ActionContainer>
          <IconButton
            aria-label="Excluir pessoa do benefício"
            icon="IconTrash"
            onClick={() => {
              const selected = [row.original._id]
              handleDeleteEmployeeAssign(selected)
            }}
            variant="line"
            size="small"
          />
        </ActionContainer>
      ),
      Header: 'Ações',
      disableSortBy: true,
      sticky: 'right',
    },
  ]

  if (isGetAllExternalBenefitsLoading && !benefits && initialLoading) {
    return (
      <LoadingContainerStyled>
        <CircularProgress variant="indeterminate" value={0} />
      </LoadingContainerStyled>
    )
  }

  if (!isGetAllExternalBenefitsLoading && !benefits?.length && !searchParam) {
    return (
      <EmptyState
        buttonAriaLabel={translation('benefitsNotFound.buttonAriaLabel')}
        buttonLabel={translation('benefitsNotFound.buttonLabel')}
        description={translation('benefitsNotFound.description')}
        handleButtonClick={handleNotFoundButtonClick}
        title={translation('benefitsNotFound.title')}
      />
    )
  }

  return (
    <>
      <PageHeader />
      <PageContainer>
        <ContainerStyled>
          <BoxStyled>
            <SearchFieldStyled
              label={translation('searchBenefit')}
              onChange={e => {
                fetchData({
                  searchParam: e?.target?.value ?? '',
                })
              }}
            />
            <BenefitsContainerStyled>
              {!initialLoading && isGetAllExternalBenefitsLoading
                ? (
                  <Skeleton
                    variant="rounded"
                    height={theme.spacings.m}
                    width={'100%'}
                  />
                )
                : (
                  tabBenefits
                )}
            </BenefitsContainerStyled>
          </BoxStyled>
          <BoxStyled>
            <Table.Root>
              {!initialLoading && isEmployeesLoading
                ? (
                  <Skeleton variant="rounded" height={'600px'} width={'100%'} />
                )
                : (
                  <>
                    <Title
                      title={`Pessoas atribuídas a ${
                        selectedBenefit?.name ? selectedBenefit.name : ''
                      }`}
                      totalCount={totalItems}
                      loading={false}
                    />
                    <Table.Search
                      clearIcon={
                        <Icons
                          name="IconX"
                          color={theme.colors.neutral[100]}
                          onClick={handleCloseSearch}
                        />
                      }
                      inputValue={searchEmployeesParam}
                      label={translation('searchEmployees')}
                      onChange={e => {
                        fetchEmployeesData({
                          pageNumber: 0,
                          pageSize,
                          searchParam: e?.target?.value ?? '',
                        })
                      }}
                    />
                    <IconButtonContainer>
                      <Tooltip
                        arrow={false}
                        placement="top"
                        title="Atribuir pessoas a este benefício"
                      >
                        <span>
                          <IconButton
                            aria-label="Atribuir pessoas ao benefício"
                            icon="IconUserPlus"
                            onClick={() =>
                              handleExternalBenefitAssign(selectedBenefit?.id)
                            }
                            variant="line"
                            size="small"
                          />
                        </span>
                      </Tooltip>
                      <Space x="xs3" />
                      <Tooltip
                        arrow={false}
                        placement="top"
                        title="Exportar atribuição deste benefício"
                      >
                        <span>
                          <IconButton
                            aria-label="Exportar atribuições do benefício"
                            icon="IconDownload"
                            onClick={() =>
                              handleOpenReportModal(selectedBenefit?.id)
                            }
                            variant="line"
                            size="small"
                          />
                        </span>
                      </Tooltip>
                    </IconButtonContainer>
                    <Table.Grid
                      pageCount={pageCount}
                      loading={isEmployeesLoading}
                      data={employees}
                      rowIdKey={'_id'}
                      columns={columns}
                      pageSize={pageSize}
                      options={{}}
                      emptyState={{
                        children: !searchEmployeesParam
                          ? (
                            <Button
                              size="large"
                              variant="primary"
                              onClick={() =>
                                handleExternalBenefitAssign(selectedBenefit?.id)
                              }
                              aria-label="Atribuir pessoas ao benefício"
                            >
                          Atribuir pessoas a este benefício
                              <Icons name="IconUserPlus" />
                            </Button>
                          )
                          : undefined,
                        isFiltered: true,
                        message: !searchEmployeesParam
                          ? translation('emptyAssignMessage')
                          : translation('emptySearchResultMessage'),
                      }}
                    />
                    <Table.Pagination
                      count={totalItems}
                      onPaginationChange={({
                        pageNumber: _pageNumber,
                        pageSize,
                      }) => {
                        fetchEmployeesData({
                          pageNumber: _pageNumber,
                          pageSize,
                          searchParam: searchEmployeesParam,
                        })
                      }}
                      pagination={{
                        index: 0,
                        pageNumber: pageNumber,
                        pageSize,
                      }}
                      pageSizeOptions={[{ label: '10 itens', value: 10 }, { label: '20 itens', value: 20 }]}
                    />
                  </>
                )}
            </Table.Root>
            <Space y="xs" />
          </BoxStyled>
        </ContainerStyled>
      </PageContainer>
      <DeletedModal
        hasEmployeeSelected={!!hasEmployeeSelected}
        isOpen={isOpenDeleteModal}
        loading={isUnassignEmployeesLoading}
        onClose={handleCloseDeleteModal}
        onSubmit={handleSubmitDeleteModal}
      />
      <SubmitReportModal
        email={adminEmail}
        isOpen={isOpenReportModal}
        loading={submitReportLoading}
        onClose={handleCloseReportModal}
        onSubmit={handleSubmitReportModal}
        theme={theme}
      />
      <SendingReportModal
        email={adminEmail}
        isOpen={isOpenReadyModal}
        onClose={handleCloseReadyModal}
        theme={theme}
      />
    </>
  )
}

export default ExternalBenefitAssignments
