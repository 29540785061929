import { BoxStyled, LinearProgressStyled } from './styled'
import { LinearProgressProps } from '@mui/material'
import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

export type ProgressBarProps = {
  children?: JSX.Element
  labelColor?: string
  height?: number
  value: number
  width?: number
} & LinearProgressProps

export const ProgressBar = ({
  children,
  labelColor,
  height,
  value,
  variant = 'determinate',
  width,
  ...props
}: ProgressBarProps) => {
  const theme = useTheme()

  return (
    <BoxStyled>
      <LinearProgressStyled
        height={height}
        value={value}
        variant={variant}
        width={width}
        {...props}
      />
      {children || (
        <Typography
          variantColor={labelColor ?? theme.colors.secondary[50]}
          variant="body4"
        >
          {`${value}%`}
        </Typography>
      )}
    </BoxStyled>
  )
}
