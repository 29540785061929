import { ContainerStyled } from './styled'
import { PageHeader } from '@flash-tecnologia/hros-web-ui-v2'
import { ReactNode } from 'react'

type RootProps = {
  children: ReactNode
}

export function Root({ children }: RootProps) {
  return (
    <PageHeader style={{ padding: 0 }}>
      <ContainerStyled>{children}</ContainerStyled>
    </PageHeader>
  )
}
