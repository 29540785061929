import { SearchField } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

const BoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
`

const BenefitsContainerStyled = styled.div`
  overflow-x: hidden;
  min-height: 700px;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
`

const ContainerStyled = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  column-gap: ${({ theme }) => theme.spacings.xs};
`

const LoadingContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TabLabelContainer = styled.div`
  display: grid;
  grid-template-columns: 8fr 1fr;
  width: 100%;
  align-items: center;
  text-align: start;
`

const TabBenefitsContainer = styled.div`
  display: grid;
  width: 100%;
  max-height: 1240px;
`
const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const TableHeaderContainerStyled = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
`

const SearchFieldStyled = styled(SearchField)`
  width: calc(100% - 16px);
  padding: 0px;
`

export {
  ActionContainer,
  BenefitsContainerStyled,
  BoxStyled,
  ContainerStyled,
  IconButtonContainer,
  LoadingContainerStyled,
  Option,
  TabLabelContainer,
  TabBenefitsContainer,
  TableHeaderContainerStyled,
  SearchFieldStyled,
}
