import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`

const LinkButtonStyled = styled(LinkButton)`
  &.linkButton-custom-theme.MuiPaper-root.MuiButtonBase-root {
    align-self: center;
  }
`

export { ContainerStyled, LinkButtonStyled }
