const TRACKING_PREFIX = 'FlashOS_ExternalBenefitSimpleAssign'

const EVENTS = {
  BACK_BUTTON_EMPLOYEE_ASSIGN: `${TRACKING_PREFIX}_BackToSelectExternalBenefit_Click`,
  CANCEL_EXTERNAL_BENEFIT_ASSIGN: `${TRACKING_PREFIX}_CancelExternalBenefitAssign_Click`,
  CHECKALL_EMPLOYEES: `${TRACKING_PREFIX}_CheckAllEmployees_Click`,
  CONTINUE_EMPLOYEE_ASSIGN: `${TRACKING_PREFIX}_ContinueToAssignEmployee_Click`,
  DELETE_EMPLOYEE_ASSIGN: `${TRACKING_PREFIX}_DeleteEmployeeAssign_Click`,
  EXPORT_EMPLOYEES_ASSIGN: `${TRACKING_PREFIX}_ExportEmployeesAssign_Click`,
  EXTERNAL_BENEFITS_TAB: `${TRACKING_PREFIX}_ExternalBenefitsTab_Click`,
  NEW_ASSIGN: `${TRACKING_PREFIX}_NewAssign_View`,
  REDIRECT_EXTERNAL_BENEFITS: `${TRACKING_PREFIX}_RedirectExternalBenefits_Click`,
  REDIRECT_NEW_ASSIGN: `${TRACKING_PREFIX}_RedirectNewAssign_ClicK`,
  SAVE_EMPLOYEES_ASSIGN: `${TRACKING_PREFIX}_SaveEmployeesAssign_Click`,
  SEARCH_EMPLOYEES: `${TRACKING_PREFIX}_SearchEmployees_Click`,
  SEARCH_EXTERNAL_BENEFITS: `${TRACKING_PREFIX}_SearchExternalBenefits_Click`,
  TRANSFER_EMPLOYEES: `${TRACKING_PREFIX}_TransferEmployees_Click`,
}

export { EVENTS }
