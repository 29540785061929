import styled from 'styled-components'

const ContainerStyled = styled.div`
  padding: ${({ theme }) => theme.spacings.m};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: ${({ theme }) => theme.spacings.m};
  height: ${({ theme }) => theme.breakpoints.values.lg}px;
`

export { ContainerStyled }
