import {
  TextArea,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

type Props = {
  handleChangeInput: ({ target }) => void
}

export const BasicInformationRightPanel = ({ handleChangeInput }: Props) => {
  const theme = useTheme()

  return (
    <>
      <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
        Dados básicos
      </Typography>
      <TextField
        id="name"
        name="name"
        label="Nome da escolha"
        inputProps={{ maxLength: 50 }}
        fullWidth
        onChange={({ target }) => handleChangeInput({ target })}
      />
      <TextArea
        id="description"
        placeholder="Adicione uma descrição para seus colaboradores"
        maxLength={600}
        onChange={({ target }) => handleChangeInput({ target })}
      />
    </>
  )
}
