import {
  BoxAdornment,
  NotificationCard,
  ShapeIcon,
  Skeleton,
  Typography,
  Unstable,
} from '@flash-tecnologia/hros-web-ui-v2'
import { LinkButtonStyled } from './styled'
import { NavigateFunction } from 'react-router-dom'
import { trpc } from '@api/client'
import { useTheme } from 'styled-components'
import FlexBox from '@components/FlexBox'
import Space from '@components/Space'
import dispatchToast from '@utils/dispatchToast.utils'

type BenefitDetailsType = {
  benefit: { name: string; companyId: string; externalPartnerId?: string }
  openBenefitDetails: boolean
  setOpenBenefitDetails: (state: boolean) => void
  navigate: NavigateFunction | undefined
}

export const BenefitDetails = ({
  benefit,
  openBenefitDetails,
  setOpenBenefitDetails,
  navigate,
}: BenefitDetailsType) => {
  const theme = useTheme()
  const { data, isLoading } = trpc.getExternalBenefitDetails.useQuery(
    {
      companyId: benefit.companyId,
      externalPartnerId: benefit?.externalPartnerId || '',
    },
    {
      onError: () => {
        setOpenBenefitDetails(false)
        dispatchToast({
          description: 'Tente novamente mais tarde.',
          title: 'Erro ao buscar detalhes do benefício.',
          type: 'error',
        })
      },
    }
  )

  const buildContractModeDescription = (
    partners: string[],
    productName: string
  ) => {
    let partnersString = `Via pacote ${productName}, que inclui os parceiros`
    if (partners.length === 2)
      return (partnersString = `${partnersString} ${partners.join(' e ')}`)

    if (partners.length > 2) {
      const lastPartner = partners.pop()
      return (partnersString = `${partnersString} ${
        partners.join(', ') + ' e ' + lastPartner
      }`)
    }
    return partnersString
  }

  return (
    <Unstable.Drawer
      onClose={() => {
        setOpenBenefitDetails(false)
      }}
      title={`Detalhes de ${benefit.name}`}
      open={openBenefitDetails}
      children={
        <>
          {isLoading
            ? (
              <Skeleton variant="rounded" height={600} />
            )
            : (
              <>
                <Typography
                  weight={700}
                  variant="headline9"
                  variantColor={theme.colors.neutral[30]}
                >
                Detalhes
                </Typography>
                {data?.isBundle && (
                  <BoxAdornment
                    style={{ width: '100%' }}
                    title="Modo de contratação"
                    description={
                      <Typography
                        weight={400}
                        variant="body4"
                        variantColor={theme.colors.neutral[50]}
                      >
                        {buildContractModeDescription(
                          data.partnerNames,
                          data.productName
                        )}
                      </Typography>
                    }
                    leftAdornment={
                      <ShapeIcon
                        size={40}
                        stroke="default"
                        variant="default"
                        name="IconShoppingCart"
                      />
                    }
                  />
                )}
                <BoxAdornment
                  style={{ width: '100%' }}
                  title="Custo por vida"
                  description={
                    <Typography
                      weight={400}
                      variant="body4"
                      variantColor={theme.colors.neutral[50]}
                    >
                      {data?.fee}
                    </Typography>
                  }
                  leftAdornment={
                    <ShapeIcon
                      size={40}
                      stroke="default"
                      variant="default"
                      name="IconPremiumRights"
                    />
                  }
                />
                <BoxAdornment
                  style={{ width: '100%' }}
                  title="Quantidade de vidas"
                  description={
                    <Typography
                      weight={400}
                      variant="body4"
                      variantColor={theme.colors.neutral[50]}
                    >
                      {data?.lives} vidas contratadas
                    </Typography>
                  }
                  leftAdornment={
                    <ShapeIcon
                      size={40}
                      stroke="default"
                      variant="default"
                      name="IconUsers"
                    />
                  }
                />
                {data?.site && (
                  <BoxAdornment
                    style={{ width: '100%' }}
                    title="Site do parceiro"
                    description={
                      <Typography
                        weight={400}
                        variant="body4"
                        variantColor={theme.colors.secondary[50]}
                      >
                        <u>
                          <a target="_blank" href={data?.site}>
                            {data?.site}
                          </a>
                        </u>
                      </Typography>
                    }
                    leftAdornment={
                      <ShapeIcon
                        size={40}
                        stroke="default"
                        variant="default"
                        name="IconDeviceDesktop"
                      />
                    }
                  />
                )}
                <FlexBox flexDirection="column" gap="xs5">
                  <Typography
                    weight={700}
                    variant="headline9"
                    variantColor={theme.colors.neutral[30]}
                  >
                  Regras de contratação
                  </Typography>
                  <FlexBox gap="xs5">
                    <Typography
                      weight={400}
                      variant="body4"
                      variantColor={theme.colors.neutral[50]}
                    >
                    Benefício contratado através de parceria
                    </Typography>
                    <LinkButtonStyled
                      variant="primary"
                      onClick={() => navigate && navigate('/marketplace')}
                    >
                    Flash Encanta
                    </LinkButtonStyled>
                  </FlexBox>
                  {data?.isBundle && (
                    <Typography
                      weight={400}
                      variant="body4"
                      variantColor={theme.colors.neutral[50]}
                    >
                    As regras de contratação são comuns a todos os parceiros
                    incluídos no mesmo pacote.
                    </Typography>
                  )}
                </FlexBox>
                <NotificationCard.Root
                  variant="outlined"
                  type="info"
                  children={
                    <>
                      <NotificationCard.Icon iconName="IconAlertCircle" />
                      <NotificationCard.WrapperTexts>
                        <NotificationCard.Title>
                        Precisa de ajuda com sua contratação?
                        </NotificationCard.Title>

                        <NotificationCard.Subtitle>
                        Para ter mais informações entre em contato através do
                        e-mail empresa@flashapp.com.br
                        </NotificationCard.Subtitle>
                      </NotificationCard.WrapperTexts>
                    </>
                  }
                />
                <Space y="m" />
              </>
            )}
        </>
      }
    />
  )
}
