import { ThemesType, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import ModalActions from '@components/ModalActions'
import Space from '@components/Space'

type SubmitReportModalType = {
  email: string
  isOpen: boolean
  loading: boolean
  onClose: () => void
  onSubmit: () => void
  theme: ThemesType
}

const SubmitReportModal = ({
  email,
  isOpen,
  loading,
  onClose,
  onSubmit,
  theme,
}: SubmitReportModalType) => {
  return (
    <ModalActions
      type="success"
      iconButton="IconDownload"
      iconHeader="IconTable"
      isOpen={isOpen}
      loading={loading}
      title="Deseja exportar a planilha de atribuição?"
      description={
        <>
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            O arquivo será enviado para o email:
          </Typography>
          <Space y="xs1" />
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[50]}
            weight={700}
          >
            {email}
          </Typography>
        </>
      }
      submitText="Exportar planilha"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}

export default SubmitReportModal
