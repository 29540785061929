import { EmptyState, Icons } from '@flash-tecnologia/hros-web-ui-v2'
import { useNavigate } from 'react-router-dom'

export const ErrorPage = () => {
  const navigate = useNavigate()
  return (
    <EmptyState
      type="error"
      title="Página não encontrada"
      description={
        'A página que você esta procurando não existe ou não foi encontrada'
      }
      buttonText={
        <>
          <span>Voltar para a página inicial</span>
          <Icons name="IconHome" />
        </>
      }
      buttonProps={{
        onClick: () => navigate('/home'),
        size: 'large',
      }}
    />
  )
}
