import {
  Button,
  Focused,
  Icons,
  LinkButton,
  Skeleton,
  TextArea,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { Grid } from '@mui/material'

import {
  BodyStyled,
  ContentWrapperStyled,
  HeadlineStyled,
  LeftContainerStyled,
  MainContainerStyled,
  RightContainerStyled,
  SecondaryTitleStyled,
} from './styled'
import Space from '../Space'

import { useTheme } from 'styled-components'
import EmojiPickerAutocomplete from '../EmojiPickerAutocomplete'

export const ExternalBenefitPage = ({
  breadCrumbsRoutes,
  disableSaveEditButton = false,
  errorEmptyName,
  externalBenefit,
  isLoading,
  isLoadingConfirm,
  onCancel,
  onChangeInput,
  onConfirm,
  title,
  translation,
}) => {
  const theme = useTheme()

  return (
    <Focused
      routes={breadCrumbsRoutes}
      footer={{
        endActions: [
          <Button
            variant="primary"
            size="large"
            onClick={onConfirm}
            loading={isLoadingConfirm || isLoading}
            disabled={disableSaveEditButton}
          >
            {translation('save')}
            <Icons name="IconCheck" fill="transparent" />
          </Button>,
        ],
        startActions: [
          <LinkButton variant="secondary" onClick={onCancel}>
            Cancelar
          </LinkButton>,
        ],
      }}
    >
      <ContentWrapperStyled>
        <MainContainerStyled>
          <Typography variant="headline6">{title}</Typography>
        </MainContainerStyled>
        <Space y="xs" />
        <Grid container paddingBottom={theme.spacings.m} spacing={2}>
          <Grid item sm={12} md={4} lg={3} paddingRight={theme.spacings.xs}>
            <LeftContainerStyled>
              <HeadlineStyled variant="headline7">
                {translation('basicBenefitData')}
              </HeadlineStyled>
              <Space y="xs1" />
              <BodyStyled variant="body3">
                {translation('enterBenefitBasicInformation')}
              </BodyStyled>
            </LeftContainerStyled>
          </Grid>
          <Grid item sm={12} md={6} lg={7}>
            <RightContainerStyled>
              <SecondaryTitleStyled variant="headline8">
                {translation('benefitData')}
              </SecondaryTitleStyled>
              <Space y="xs" />
              {isLoading
                ? (
                  <Skeleton
                    variant="text"
                    height={theme.spacings.l}
                    width={'100%'}
                  />
                )
                : (
                  <TextField
                    id={'name'}
                    name={'name'}
                    label={translation('benefitName')}
                    inputProps={{ maxLength: 50 }}
                    onChange={onChangeInput}
                    value={externalBenefit.name}
                    fullWidth
                    error={errorEmptyName}
                    helperText={
                      errorEmptyName && translation('errorEmptyBenefitName')
                    }
                  />
                )}
              <Space y="xs" />
              {isLoading
                ? (
                  <Skeleton
                    variant="text"
                    height={theme.spacings.xl}
                    width={'100%'}
                  />
                )
                : (
                  <TextArea
                    id="description"
                    value={externalBenefit.description}
                    placeholder={translation('addDescription')}
                    maxLength={600}
                    width={'100%'}
                    onChange={onChangeInput}
                  />
                )}
            </RightContainerStyled>
            <RightContainerStyled>
              <SecondaryTitleStyled variant="headline8">
                {translation('benefitIdentificationIcon')}
              </SecondaryTitleStyled>
              {isLoading
                ? (
                  <Skeleton
                    variant="text"
                    height={theme.spacings.l}
                    width={'50%'}
                  />
                )
                : (
                  <>
                    <Space y="xs" />
                    <EmojiPickerAutocomplete
                      value={externalBenefit.emoji}
                      label={translation('identificationIcon')}
                      name={'emoji'}
                      onChange={onChangeInput}
                    />
                  </>
                )}
            </RightContainerStyled>
          </Grid>
        </Grid>
      </ContentWrapperStyled>
    </Focused>
  )
}
