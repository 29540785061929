import { TagStyledProps } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Tag/styled'
import { TooltipBenefitsInfoType } from './types'

export const STATUS = {
  APPLIED_CHOICE: 'Escolha aplicada',
  DEFAULT_APPLIED_CHOICE: 'Escolha padrão aplicada',
  EXPIRED_PERIOD: 'Prazo vencido',
  MADE_CHOICE: 'Escolha realizada',
  NO_CHOICE: 'Não escolhido',
  NONE_BENEFIT: 'Nenhum benefício',
  PENDING_CHOICE: 'Escolha pendente',
  PENDING_COMMUNICATION: 'Comunicação pendente',
}

export const choiceStatus = (status: string) => {
  const statusColor =
    {
      [STATUS.PENDING_COMMUNICATION]: 'error',
      [STATUS.APPLIED_CHOICE]: 'success',
      [STATUS.DEFAULT_APPLIED_CHOICE]: 'primary',
      [STATUS.PENDING_CHOICE]: 'gray',
      [STATUS.MADE_CHOICE]: 'info',
      [STATUS.EXPIRED_PERIOD]: 'gray',
    }[status] ?? 'gray'

  const color = statusColor as TagStyledProps['variant']
  return color
}

export const benefitChooseStatus = (
  status: string
): TagStyledProps['variant'] => {
  const statusColor =
    {
      [STATUS.PENDING_COMMUNICATION]: 'gray',
      [STATUS.APPLIED_CHOICE]: 'success',
      [STATUS.DEFAULT_APPLIED_CHOICE]: 'gray',
      [STATUS.MADE_CHOICE]: 'info',
      [STATUS.NO_CHOICE]: 'gray',
      [STATUS.EXPIRED_PERIOD]: 'gray',
    }[status] ?? 'gray'

  const color = statusColor as TagStyledProps['variant']
  return color
}

export const benefitAssignStatus = (
  status: string,
  benefit: string
): TagStyledProps['variant'] => {
  if (benefit === STATUS.NONE_BENEFIT) return 'gray'

  const statusColor =
    {
      [STATUS.PENDING_COMMUNICATION]: 'error',
      [STATUS.APPLIED_CHOICE]: 'success',
      [STATUS.DEFAULT_APPLIED_CHOICE]: 'primary',
      [STATUS.MADE_CHOICE]: 'error',
      [STATUS.NO_CHOICE]: 'gray',
      [STATUS.EXPIRED_PERIOD]: 'error',
    }[status] ?? 'gray'

  const color = statusColor as TagStyledProps['variant']
  return color
}

export const tooltipBenefitsInfo: TooltipBenefitsInfoType = [
  {
    description: 'Nenhum benefício atribuído',
    tagColor: 'gray',
    tagText: 'Nenhum',
  },
  {
    description: 'Benefício previamente atribuído',
    tagColor: 'error',
    tagText: 'Benefício',
  },
  {
    description: 'Benefício atribuído pela Escolha',
    tagColor: 'success',
    tagText: 'Benefício',
  },
  {
    description: 'Opção padrão atribuída pela empresa',
    tagColor: 'primary',
    tagText: 'Benefício',
  },
]
