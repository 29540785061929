import { trpc } from '@api/client'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'
import dispatchToast from '@utils/dispatchToast.utils'

export const useCreateNewChoice = () => {
  const [newChoice, setNewChoice] = useState({
    benefits: [],
    defaultBenefit: undefined,
    description: '',
    id: '',
    name: '',
  })

  const location = useLocation()
  const state = location.state as { step: string | undefined }
  const step = Number(state?.step) || 0
  const [activeStep, setActiveStep] = useState(step)
  const navigate = useNavigate()

  const { mutate: createChoice } = trpc.createChoice.useMutation({
    onError: error => {
      dispatchToast({
        content: error.message,
        type: 'error',
      })
    },
    onSuccess: ({ id }) => {
      const newNewChoice = { ...newChoice, id: id ?? '' }
      setNewChoice(newNewChoice)

      navigate('/benefit-choices/assign-employees-to-choice', {
        state: { newChoice: newNewChoice, step: activeStep + 1 },
      })
    },
  })

  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()

  const handleChangeInput = ({ target }) => {
    setNewChoice({
      ...newChoice,
      [target.id]: target.value,
    })
  }

  const handleNextStep = () => {
    if (activeStep < 2) {
      const newActiveStep = activeStep + 1
      setActiveStep(newActiveStep)
    } else if (activeStep === 2) {
      const defaultBenefit =
        newChoice.defaultBenefit ??
        (newChoice.benefits[0] as { id: string } | undefined)?.id ??
        null
      createChoice({
        ...newChoice,
        companyId,
        defaultBenefit: defaultBenefit === '__NONE__' ? null : defaultBenefit,
        nullableChoiceEnabled: newChoice.benefits.some(
          ({ id }) => id === '__NONE__'
        ),
      })
    }
  }

  const handlePreviousStep = () => {
    if (activeStep > 0) {
      const newActiveStep = activeStep - 1
      setActiveStep(newActiveStep)
    }
  }

  const handleCancel = () => {
    setCancelModalOpen(activeStep === 1)
    if (activeStep !== 1) navigate('/simple-assignment/benefit-choices')
  }

  const selectedBenefitsContainsDefaultBenefit = newChoice.benefits.some(
    ({ id }) => newChoice.defaultBenefit === id
  )
  const defaultBenefit = selectedBenefitsContainsDefaultBenefit
    ? newChoice.defaultBenefit
    : (newChoice.benefits[0] as { id: string } | undefined)?.id

  return {
    activeStep,
    cancelModalOpen,
    defaultBenefit,
    handleCancel,
    handleChangeInput,
    handleNextStep,
    handlePreviousStep,
    selectedBenefits: newChoice.benefits,
    setCancelModalOpen,
  }
}
