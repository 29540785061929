import { BenefitDetails } from '@components/BenefitDetails'
import { EmojiNameTab } from '@components/EmojiNameTab'
import { ExternalBenefitEmptyStateBanner } from '@components/ExternalBenefitEmptyStateBanner'
import { HeaderExternalBenefit } from '@components/HeaderExternalBenefit'
import {
  IconButton,
  Icons,
  Skeleton,
  Table,
} from '@flash-tecnologia/hros-web-ui-v2'
import { NavigateFunction } from 'react-router-dom'
import { StyledEmptyStateButton, StyledEmptyStateContainer, StyledEmptyStateMessage } from './styled'
import { limitText } from '../../helpers/limitText'
import { useExternalBenefitTable } from './useExternalBenefitTable'
import { useState } from 'react'
import ModalActions from '@components/ModalActions'
import Space from '@components/Space'
import dispatchToast from '@utils/dispatchToast.utils'

type ExternalBenefitTableRow = {
  values: {
    id: string
    name: string
    description: string
  }
  original: {
    emoji: string
  }
}

type Benefit = {
  companyId: string
  externalPartnerId?: string
  name: string
}

const ExternalBenefitTable = ({
  navigate,
}: {
  navigate: NavigateFunction | undefined
}) => {
  const {
    benefits,
    isFetching,
    pagination,
    setPagination,
    setParams,
    setSearchTerm,
    totalBenefits,
    translation,
    closeDeleteBenefitConfirmationModal,
    isDeleteBenefitConfirmationModalVisible,
    isDeleteExternalBenefitLoading,
    submitDeleteBenefitConfirmationModal,
    showInitialEmptyState,
    isInitialLoading,
    theme,
  } = useExternalBenefitTable({ navigate })

  const [openBenefitDetails, setOpenBenefitDetails] = useState<boolean>(false)
  const [benefit, setBenefit] = useState<Benefit>({
    companyId: '',
    externalPartnerId: '',
    name: '',
  })

  const MAX_TEXT_LENGTH = 100

  const columns = [
    {
      Cell: ({ row }: { row: ExternalBenefitTableRow }) => {
        return (
          <EmojiNameTab name={row?.values.name} emoji={row?.original?.emoji} />
        )
      },
      Header: translation('benefit'),
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Cell: ({ row }: { row: ExternalBenefitTableRow }) => {
        return limitText({
          limit: MAX_TEXT_LENGTH,
          text: row?.values.description,
        })
      },
      Header: translation('description'),
      accessor: 'description',
      disableSortBy: true,
    },
    {
      Cell: ({ row }: { row: ExternalBenefitTableRow }) => {
        return (
          // DISABLED FEATURE
          // <Menu type='default' options={[
          //   {
          //     children: (
          //       <MenuOption
          //         label="Editar benefício"
          //         icon="IconPencil"
          //         onClick={() => navigate && navigate(
          //           {
          //             pathname: '/external-benefit/edit',
          //             search: createSearchParams({
          //               company: companyId,
          //             }).toString(),
          //           },
          //           { state: { externalBenefitId: `${row?.values.id}` } },
          //         )}
          //       />
          //     ),
          //   },
          //   {
          //     children: (
          //       <MenuOption
          //         label="Excluir benefício"
          //         icon="IconTrash"
          //         onClick={() => openDeleteBenefitConfirmationModal(row.values.id)}
          //       />
          //     ),
          //   },
          // ]}>
          <IconButton
            title={translation('editExternalBenefit')}
            size="small"
            variant="line"
            icon="IconFileDescription"
            onClick={() => {
              const selectedBenefit = benefits.find(
                ({ id }) => id === row.values.id
              )
              if (!selectedBenefit?.externalPartnerId) {
                dispatchToast({
                  description: 'Tente novamente mais tarde.',
                  title: 'Erro ao buscar detalhes do benefício.',
                  type: 'error',
                })
                return
              }
              setBenefit(selectedBenefit)
              setOpenBenefitDetails(true)
            }}
          />
          // </Menu>
        )
      },
      Header: translation('details'),
      accessor: 'id',
      disableSortBy: true,
    },
  ]

  if (isInitialLoading) return <Skeleton variant="rounded" height={800} />

  return (
    <>
      {showInitialEmptyState && (
        <>
          <ExternalBenefitEmptyStateBanner navigate={navigate} />
          <Space y="xs" />
        </>
      )}
      <HeaderExternalBenefit
        totalCount={benefits.length}
        loading={isFetching}
      />
      <Space y="xs" />
      <Table.Search
        clearIcon={<Icons name="IconX" onClick={() => setSearchTerm('')} />}
        label={translation('searchExternalBenefits')}
        disabled={showInitialEmptyState}
        onChange={({ target }) => {
          setSearchTerm(target.value)
        }}
      />
      <Space y="xs" />
      <Table.Root>
        {isFetching && <Skeleton variant="rounded" height={600} />}
        {!isFetching && (
          <Table.Grid
            columns={columns}
            data={benefits.map(({ name, description, id, emoji }) => ({
              description,
              emoji,
              id,
              name,
            }))}
            fetchData={setParams}
            loading={isFetching}
            rowIdKey={'_id'}
            pageSize={pagination.pageSize}
            options={{}}
            emptyState={{
              children: showInitialEmptyState
                ? (
                  <StyledEmptyStateContainer>
                    <StyledEmptyStateMessage
                      variant="body3"
                      variantColor={theme.colors.neutral[30]}
                      weight={600}
                    >
                      Você ainda não adicionou um<br />
                      benefício externo.<br />
                      Utilize nossa plataforma para descobrir<br />
                      vantagens em nossos parceiros exclusivos.
                    </StyledEmptyStateMessage>
                    <StyledEmptyStateButton
                      size="large"
                      variant="primary"
                      onClick={() => navigate && navigate('/marketplace')}
                      aria-label={translation('notFound.marketplaceCTA')}
                    >
                      {translation('notFound.marketplaceCTA')}
                    </StyledEmptyStateButton>
                  </StyledEmptyStateContainer>
                )
                : undefined,
              isFiltered: true,
              message: showInitialEmptyState ? '' : translation('noBenefits'),

            }}
          />
        )}
        <Table.Pagination
          count={totalBenefits}
          onPaginationChange={value => setPagination(value)}
          pagination={pagination}
          pageSizeOptions={[{ label: '10 itens', value: 10 }, { label: '20 itens', value: 20 }]}
        />
      </Table.Root>
      <Space y="xs" />
      <ModalActions
        subtitle="Atenção!"
        type="confirmation"
        iconButton="IconTrash"
        iconHeader="IconAlertCircle"
        isOpen={isDeleteBenefitConfirmationModalVisible}
        loading={isDeleteExternalBenefitLoading}
        title="Excluir benefício externo"
        description={`Tem certeza de que deseja excluir o benefício externo? 
        Isso afetará todos os colaboradores atribuídos a este benefício.`}
        submitText="Confirmar exclusão"
        onClose={closeDeleteBenefitConfirmationModal}
        onSubmit={submitDeleteBenefitConfirmationModal}
      />
      {openBenefitDetails && (
        <BenefitDetails
          navigate={navigate}
          openBenefitDetails={openBenefitDetails}
          benefit={benefit}
          setOpenBenefitDetails={setOpenBenefitDetails}
        />
      )}
    </>
  )
}

export default ExternalBenefitTable
