export const ERROR_MESSAGES = {
  BENEFIT_ASSIGNMENT_FORBIDDEN: `Não é possível atribuir pessoas. 
  Por se tratar de uma parceria Flash Encanta, 
    o benefício contempla todas as pessoas ativas. Para saber mais, fale com nossos especialistas.`,
  BENEFIT_DELETE_FORBIDDEN:
    'Não é possível excluir o benefício pois o mesmo se trata de parceria Flash Encanta.',
  BENEFIT_EDIT_FORBIDDEN:
    'Não é possível editar o benefício pois o mesmo se trata de parceria Flash Encanta.',
  UNASSIGNMENT_EMPLOYEE_FORBIDDEN: `Não é possível excluir atribuição pois o benefício contempla todas 
    as pessoas ativas.`,
}
