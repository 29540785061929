import { Button } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ImageStyled = styled.img`
  object-fit: scale-down;
`

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #E6007C;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.m};
`

export const StyledTextContainer = styled.div`
  flex: 4;
  padding-right: ${({ theme }) => theme.spacings.xs1};
`

export const StyledImageContainer = styled.div`
  flex: 6;
  display: flex;
  justify-content: end;
`

export const StyledEmptyStateButton = styled(Button)`
  background-color: #FFFFFF !important;
`
