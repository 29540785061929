import { TransferList } from '@flash-tecnologia/hros-web-ui-v2'

import { ContainerListStyled } from './styled'
import { useEmployeesList } from './useEmployeesList'
import EmployeeItem from '@components/EmployeeItem'

const EmployeesList = ({ setSelectedEmployees }) => {
  const {
    handleLeftCheck,
    handleLeftSearchEmployees,
    handleRightCheck,
    handleRightSearchEmployees,
    handleTransferEmployee,
    isLoadingEmployees,
    leftListData,
    rightListData,
  } = useEmployeesList({ setSelectedEmployees })

  const columns = [
    {
      Cell: ({ row }) => (
        <EmployeeItem
          hasAttribution={row?.original?.hasAttribution}
          name={row?.original?.name}
          cpf={row?.original?.cpf}
          isFromChoice={true}
          hasAttributionInOtherChoice={
            row?.original?.hasAttributionInOtherChoice
          }
        />
      ),
      Header: 'Nome',
      accessor: 'name',
      disableSortBy: true,
    },
  ]

  return (
    <div>
      <ContainerListStyled>
        <TransferList
          columns={columns}
          leftList={{
            data: leftListData.filter(({ hidden }) => !hidden),
            loading: isLoadingEmployees,
            onCheck: handleLeftCheck,
            onSearch: handleLeftSearchEmployees,
            title: `Todas pessoas (${leftListData.length})`,
            total: leftListData.length,
          }}
          rightList={{
            data: rightListData,
            loading: false,
            onCheck: handleRightCheck,
            onSearch: handleRightSearchEmployees,
            title: `Selecionados para a atribuição (${rightListData.length})`,
            total: rightListData.length,
          }}
          onTransfer={handleTransferEmployee}
        />
      </ContainerListStyled>
    </div>
  )
}
export default EmployeesList
