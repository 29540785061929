import { STATUS } from './consts'
import { trpc } from '@api/client'
import { useDispatchToast } from '@utils/hooks/useDispatchToast'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { useTheme } from 'styled-components'

type ChoiceType = {
  benefitNames: string[]
  defaultBenefitName: string
  name: string
  description: string
}

export const useChoiceDetails = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { choice_id: choiceId = '' } = useParams()

  const { dispatchToast } = useDispatchToast()

  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()

  const {
    data: choice = {
      benefitNames: [''],
      defaultBenefitName: '',
      description: '',
      name: '',
    },
    isLoading: isLoadingChoice,
  }: { isLoading: boolean; data?: ChoiceType } = trpc.getChoice.useQuery(
    { companyId, id: choiceId },
    {
      enabled: companyId !== null && companyId !== undefined,
      onError: () =>
        dispatchToast({
          description: 'Tente novamente mais tarde.',
          title: 'Erro ao buscar detalhes da escolha.',
          type: 'error',
        }),
    }
  )

  const { data: employees, isLoading: isLoadingEmployees } =
    trpc.getEmployeesByChoice.useQuery({ id: choiceId ?? '' })

  const handleDisabledAppliedButton = (status: string) => {
    const disabled =
      status === STATUS.APPLIED_CHOICE ||
      status === STATUS.DEFAULT_APPLIED_CHOICE ||
      status === STATUS.PENDING_COMMUNICATION

    return disabled
  }

  const names = choice.benefitNames
  const accordionDescription =
    names.length === 1
      ? names[0]
      : `Escolha entre ${names
        .slice(1, names.length - 1)
        .reduce(
          (benefitNames: string, name) => `${benefitNames}, ${name}`,
          names[0]
        )} e ${names[names.length - 1]}`

  return {
    accordionDescription,
    choice: {
      ...choice,
      benefitNames: choice.benefitNames.map(
        name =>
          `${name}${
            name === choice.defaultBenefitName ? ' (Escolha Padrão)' : ''
          }`
      ),
    },
    choiceId,
    employees,
    handleDisabledAppliedButton,
    isLoading: isLoadingEmployees || isLoadingChoice,
    navigate,
    theme,
  }
}
