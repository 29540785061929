import styled from 'styled-components'

const TableContainerStyled = styled.div`
  align-self: stretch;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.s};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings.m};
`

export { TableContainerStyled }
