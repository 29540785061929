import { EVENTS } from '@utils/consts/tracking'
import { trpc } from '@api/client'
import { useDispatchToast } from '@utils/hooks/useDispatchToast'
import { useEffect, useState } from 'react'
import { useEventTracking } from '@utils/hooks/useEventTracking'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'

export const useNewAssign = () => {
  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { trackPage, trackEvent } = useEventTracking()
  const { dispatchToast } = useDispatchToast()

  const state = location.state as {
    externalBenefitId: string
    step: string | undefined
  }
  const step = Number(state?.step) || 0
  const initialExternalBenefitId = state?.externalBenefitId || ''

  const [externalBenefitId, setExternalBenefitId] = useState<string>(
    initialExternalBenefitId
  )
  const [employeesToAssign, setEmployeesToAssign] = useState<
    [{ _id: string }] | []
  >([])

  const [activeStep, setActiveStep] = useState(step)
  const [icon, setIcon] = useState<'IconArrowRight' | 'IconCheck'>(
    'IconArrowRight'
  )

  const [subtitle, setSubtitle] = useState('')
  const [description, setDescription] = useState('')
  const [rightButtonLabel, setRightButtonLabel] = useState('Continuar')

  useEffect(() => {
    if (activeStep === 0) {
      setSubtitle('Escolher benefícios')
      setDescription('Escolha os benefícios que serão atribuídos')
    }

    if (activeStep === 1) {
      setSubtitle('Adicionar pessoas')
      setDescription('Selecione as pessoas que serão atribuídas')
      setRightButtonLabel('Salvar')
      setIcon('IconCheck')
    }

    trackPage({
      name: EVENTS.NEW_ASSIGN,
      params: { hasBenefits: 'true' },
    })
  }, [
    activeStep,
    trackPage,
    state,
  ])

  const handleModalConfirm = () => {
    setExternalBenefitId('')
    setIsOpen(false)
    navigate('/simple-assignment/external-benefit-simple-assign')
  }

  const handleModalCancel = () => {
    setIsOpen(false)
  }

  const handleCancelAssign = () => {
    setIsOpen(true)
    trackEvent({
      name: EVENTS.CANCEL_EXTERNAL_BENEFIT_ASSIGN,
    })
  }

  const assignEmployeesExternalBenefitMutation =
    trpc.assignEmployeesExternalBenefit.useMutation({
      onError: () => {
        dispatchToast({
          description: 'Verifique as informações e tente novamente.',
          title: 'Erro ao atribuir os benefícios.',
          type: 'error',
        })
      },
      onSuccess: () => {
        dispatchToast({
          content: 'Benefícios atribuídos com sucesso!',
          type: 'success',
        })
        navigate('/simple-assignment/external-benefit-simple-assign')
      },
    })

  const handleNextPage = () => {
    if (activeStep === 0) {
      trackEvent({
        name: EVENTS.CONTINUE_EMPLOYEE_ASSIGN,
      })
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    if (activeStep === 1) {
      trackEvent({
        name: EVENTS.SAVE_EMPLOYEES_ASSIGN,
      })
      assignEmployeesExternalBenefitMutation.mutate({
        benefitId: externalBenefitId,
        companyId,
        employeeIds: employeesToAssign.map(({ _id }) => _id),
      })
    }
  }

  const handlePreviousPage = () => {
    trackEvent({
      name: EVENTS.BACK_BUTTON_EMPLOYEE_ASSIGN,
    })
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    setRightButtonLabel('Continuar')
    setIcon('IconArrowRight')
  }

  return {
    activeStep,
    description,
    employeesToAssign,
    externalBenefitId,
    handleCancelAssign,
    handleModalCancel,
    handleModalConfirm,
    handleNextPage,
    handlePreviousPage,
    icon,
    isLoadingAssign: assignEmployeesExternalBenefitMutation.isLoading,
    isOpen,
    rightButtonLabel,
    setEmployeesToAssign,
    setExternalBenefitId,
    subtitle,
  }
}
