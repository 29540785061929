import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient, trpc, trpcClient } from '@api/client'
import BenefitChoices from '../../pages/BenefitChoices'

const BenefitChoicesTab = ({ navigate = undefined }) => {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <BenefitChoices navigate={navigate} />
      </QueryClientProvider>
    </trpc.Provider>
  )
}

export default BenefitChoicesTab
