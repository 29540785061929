import styled, { type DefaultTheme } from 'styled-components'

type SpaceProps = {
  x?: keyof DefaultTheme['spacings']
  y?: keyof DefaultTheme['spacings']
  useMargin?: boolean
}

export default styled.div<SpaceProps>`
  ${({ useMargin }) => (!useMargin ? 'width' : 'margin-right')}: ${({
  x,
  theme,
}) => (x ? theme.spacings[x] : 0)};
  ${({ useMargin }) => (!useMargin ? 'height' : 'margin-top')}: ${({
  y,
  theme,
}) => (y ? theme.spacings[y] : 0)};
`
