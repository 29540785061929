import styled from 'styled-components'

const ContainerStyled = styled.div`
  border-radius: ${({ theme }) => theme.spacings.xs4};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.s};
`

const RadioContainerStyled = styled.div`
  border-radius: ${({ theme }) => theme.spacings.xs4};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs2}
    ${({ theme }) => theme.spacings.xs};
  display: flex;
  align-items: center;
  flex: 1 0 0;
`
const CommunicationContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`

const DateContainerStyled = styled.div`
  width: 50%;
`

export {
  CommunicationContainerStyled,
  ContainerStyled,
  DateContainerStyled,
  RadioContainerStyled,
}
