import { trpc } from '@api/client'
import { useCallback, useEffect, useState } from 'react'
import { useDispatchToast } from '@utils/hooks/useDispatchToast'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'

type Choice = {
  id: string
  name: string
  benefits: { id: string }[]
}

enum NotificationOption {
  SEND_NOW = 'sendNow',
  SEND_LATER = 'sendLater',
}

export const useAssignEmployeesToChoice = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const state = location.state as { step?: number; newChoice?: Choice } | null

  const [newChoice, setNewChoice] = useState(state?.newChoice)
  const [flowCurrentStep, setFlowCurrentStep] = useState<number>(
    state?.step ?? 3
  )
  const [isNoEmployeesSelectedModalVisible, setIsNoEmployeesSelectedModalVisible] = useState(false)

  useEffect(() => {
    if (state) {
      setNewChoice(state?.newChoice)
      setFlowCurrentStep(state?.step ?? 3)
    }
  }, [state])

  const [activeStep, setActiveStep] = useState<number>(0)
  const [selected, setSelected] = useState<NotificationOption>(
    NotificationOption.SEND_NOW
  )

  const [selectedEmployees, setSelectedEmployees] = useState([])

  const {
    selectedCompany: { id: companyId },
  } = useSelectedCompany()
  const { dispatchToast } = useDispatchToast()
  const { mutate: createElectionMutation } = trpc.createElection.useMutation({
    mutationKey: [
      'createElection',
      {
        choices: [newChoice?.id ?? ''],
        companyId,
        name: newChoice?.name ?? '',
        notification: selected,
        selectedEmployees,
      },
    ],
    onError: () => {
      dispatchToast({
        description: 'Tente novamente mais tarde.',
        title: 'Erro ao criar a escolha.',
        type: 'error',
      })
    },
    onSuccess: () => {
      dispatchToast({
        content: 'Escolha criada com sucesso!',
        type: 'success',
      })
      if (selected === NotificationOption.SEND_NOW) {
        dispatchToast({
          content: 'Comunicação enviada com sucesso!',
          type: 'success',
        })
      }

      navigate('/simple-assignment/benefit-choices')
    },
  })

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const createElection = useCallback(() => {
    if (!newChoice) {
      dispatchToast({
        description: 'Tente novamente mais tarde.',
        title: 'Erro ao criar a escolha.',
        type: 'error',
      })

      return
    }

    setConfirmModalOpen(false)
    createElectionMutation({
      choices: [newChoice.id ?? ''],
      companyId,
      name: newChoice.name ?? '',
      notification: selected,
      selectedEmployees,
    })
  }, [
    companyId,
    createElectionMutation,
    dispatchToast,
    newChoice,
    selected,
    selectedEmployees,
  ])

  const handleNextStep = () => {
    if (flowCurrentStep === 3 && !selectedEmployees.length) {
      setIsNoEmployeesSelectedModalVisible(true)
      return
    }
    setActiveStep(activeStep + 1)
    setFlowCurrentStep(flowCurrentStep + 1)
  }

  const confirmNoEmployeesSelected = () => {
    setIsNoEmployeesSelectedModalVisible(false)
    setActiveStep(activeStep + 1)
    setFlowCurrentStep(flowCurrentStep + 1)
  }

  const handlePreviousStep = () => {
    if (flowCurrentStep === 4) setSelectedEmployees([])
    if (activeStep === 1) setActiveStep(activeStep - 1)
    if (activeStep < 1)
      navigate('/benefit-choices/create-new-choice', {
        state: { step: flowCurrentStep - 1 },
      })
    setFlowCurrentStep(flowCurrentStep - 1)
  }

  const handleCancel = () => {
    navigate('/simple-assignment/benefit-choices')
  }

  const handleSave = () => {
    if (selected === NotificationOption.SEND_LATER) {
      createElection()
      return
    }

    setConfirmModalOpen(true)
  }

  const handleCheckSendLater = () => setSelected(NotificationOption.SEND_LATER)
  const handleCheckSendNow = () => setSelected(NotificationOption.SEND_NOW)

  return {
    activeStep,
    confirmModalOpen,
    confirmNoEmployeesSelected,
    flowCurrentStep,
    handleCancel,
    handleCheckSendLater,
    handleCheckSendNow,
    handleConfirm: createElection,
    handleNextStep,
    handlePreviousStep,
    handleSave,
    isNoEmployeesSelectedModalVisible,
    navigate,
    selected,
    setConfirmModalOpen,
    setIsNoEmployeesSelectedModalVisible,
    setSelectedEmployees,
  }
}
