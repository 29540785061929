import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

const HeaderContainerStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
`

const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export { HeaderContainerStyled, TitleStyled }
