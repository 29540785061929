import 'dotenv/config'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CreateExternalBenefit } from '../pages/CreateExternalBenefit'
import { EditExternalBenefit } from '../pages/EditExternalBenefit'
import { ErrorPage } from '../pages/ErrorPage'
import { PermissionEnum, VerifyPermission } from '@components/VerifyPermission'
import { useRegisterRoutes } from '@flash-tecnologia/hros-web-utility'
import AssignEmployeesToChoice from '../pages/AssignEmployeesToChoice'
import ChoiceDetails from '../pages/ChoiceDetails'
import CreateNewChoice from '../pages/CreateNewChoice'
import EditChoice from '../pages/EditChoice'
import NewAssign from '../pages/NewAssign'

const AppRouter = () => {
  useRegisterRoutes([
    {
      hideHeader: true,
      path: '/external-benefit/create',
    },
    {
      hideHeader: true,
      path: '/external-benefit/edit',
    },
    {
      hideHeader: true,
      path: '/external-benefit-simple-assign/new-assign',
    },
    {
      hideHeader: true,
      path: '/benefit-choices/create-new-choice',
    },
    {
      hideHeader: true,
      path: '/benefit-choices/assign-employees-to-choice',
    },
  ])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/external-benefit/error-page" element={<ErrorPage />} />
        <Route
          path="/external-benefit/create"
          element={
            <VerifyPermission
              component={<CreateExternalBenefit />}
              permission={PermissionEnum.EXTERNAL_BENEFIT_VIEW}
            />
          }
        />
        <Route
          path="/external-benefit/edit"
          element={
            <VerifyPermission
              component={<EditExternalBenefit />}
              permission={PermissionEnum.EXTERNAL_BENEFIT_VIEW}
            />
          }
        />
        <Route
          path="/external-benefit-simple-assign/new-assign"
          element={
            <VerifyPermission
              component={<NewAssign />}
              permission={PermissionEnum.EXTERNAL_BENEFIT_ASSIGN_VIEW}
            />
          }
        />
        <Route
          path="/benefit-choices/assign-employees-to-choice"
          element={
            <VerifyPermission
              component={<AssignEmployeesToChoice />}
              permission={PermissionEnum.CHOICE_VIEW}
            />
          }
        />
        <Route
          path="/benefit-choices/choice/:choice_id"
          element={
            <VerifyPermission
              component={<ChoiceDetails />}
              permission={PermissionEnum.CHOICE_VIEW}
            />
          }
        />
        <Route
          path="/benefit-choices/edit-choice/:choice_id"
          element={
            <VerifyPermission
              component={<EditChoice />}
              permission={PermissionEnum.CHOICE_VIEW}
            />
          }
        />
        <Route
          path="/benefit-choices/create-new-choice"
          element={
            <VerifyPermission
              component={<CreateNewChoice />}
              permission={PermissionEnum.CHOICE_VIEW}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
