import { BoxStyled } from '../styled'
import { Icons, IconsProps, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

type IconsAndLabelType = {
  label: string
  icon: IconsProps['name']
}

const IconsAndLabel = ({ label, icon = 'IconUsers' }: IconsAndLabelType) => {
  const theme = useTheme()

  return (
    <BoxStyled>
      <Icons name={icon} color={theme.colors.neutral[30]} />
      <Typography variant="body3" color={theme.colors.neutral[40]}>
        {label}
      </Typography>
    </BoxStyled>
  )
}

export default IconsAndLabel
