import {
  Button,
  Focused,
  Icons,
  LinkButton,
  RoutesProps,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import {
  ContentGridContainerStyled,
  LeftPanelContainerStyled,
  PanelTitleStyled,
  RightPanelContainerStyled,
} from './styled'
import { useNavigate } from 'react-router-dom'
import { useNewAssign } from './useNewAssign'
import { useTheme } from 'styled-components'
import ChooseExternalBenefit from '../ChooseExternalBenefit'
import ExternalBenefitAssignToEmployees from '../ExternalBenefitAssignToEmployees'
import ModalActions from '@components/ModalActions'
import Space from '@components/Space'

const NewAssign = () => {
  const {
    activeStep,
    description,
    externalBenefitId,
    handleCancelAssign,
    handleModalCancel,
    handleModalConfirm,
    handleNextPage,
    handlePreviousPage,
    icon,
    isLoadingAssign,
    isOpen,
    rightButtonLabel,
    setEmployeesToAssign,
    employeesToAssign,
    setExternalBenefitId,
    subtitle,
  } = useNewAssign()
  const theme = useTheme()
  const navigate = useNavigate()

  const breadcrumbRoutes: RoutesProps[] = [
    {
      label: 'Atribuição de benefícios',
      onClick: () =>
        navigate('/simple-assignment/external-benefit-simple-assign'),
      route: '/simple-assignment/external-benefit-simple-assign',
    },
    {
      label: 'Nova atribuição',
      route: '/external-benefit-simple-assign/new-assign',
    },
  ]

  const steps = ['Benefícios', 'Pessoas']

  return (
    <Focused
      routes={breadcrumbRoutes}
      footer={{
        endActions: [
          activeStep === 1
            ? (
              <Button
                variant="secondary"
                size="large"
                onClick={handlePreviousPage}
              >
                <Icons name="IconArrowLeft" />
              Voltar
              </Button>
            )
            : null,
          <Button
            variant="primary"
            size="large"
            onClick={handleNextPage}
            disabled={
              !externalBenefitId ||
              (employeesToAssign.length === 0 && activeStep === 1)
            }
            loading={isLoadingAssign}
          >
            {rightButtonLabel}
            <Icons name={icon} fill="transparent" />
          </Button>,
        ],
        startActions: [
          <LinkButton variant="secondary" onClick={handleCancelAssign}>
            Cancelar
          </LinkButton>,
        ],
      }}
      stepper={{
        activeStep,
        steps,
      }}
    >
      <Typography variant="headline6" variantColor={theme.colors.neutral[10]}>
        Nova atribuição
      </Typography>
      <Space y="xs" />
      <ContentGridContainerStyled>
        <LeftPanelContainerStyled>
          <PanelTitleStyled
            variant="headline7"
            variantColor={theme.colors.secondary[50]}
          >
            {subtitle}
          </PanelTitleStyled>

          <Typography variant="body3" variantColor={theme.colors.neutral[50]}>
            {description}
          </Typography>
        </LeftPanelContainerStyled>
        <RightPanelContainerStyled>
          {activeStep === 0
            ? (
              <ChooseExternalBenefit
                setExternalBenefitId={setExternalBenefitId}
                externalBenefitId={externalBenefitId}
              />
            )
            : (
              <ExternalBenefitAssignToEmployees
                externalBenefitId={externalBenefitId}
                setEmployeesToAssign={setEmployeesToAssign}
              />
            )}
        </RightPanelContainerStyled>
      </ContentGridContainerStyled>
      <ModalActions
        type="confirmation"
        iconHeader="IconAlertCircle"
        subtitle="Atenção!"
        isOpen={isOpen}
        loading={false}
        title="Cancelar atribuição"
        description="Tem certeza que quer cancelar a atribuição dos benefícios?"
        submitText="Confirmar"
        onClose={handleModalCancel}
        onSubmit={handleModalConfirm}
      />
    </Focused>
  )
}

export default NewAssign
